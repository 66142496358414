.landing-cards-wrapper{
    margin: 0 auto;
    padding: 64px 0 24px;
    max-width: 800px;
    .landing-card{
        padding: 1rem;
        transition: all 0.1s ease-in-out;
        box-shadow: 0 0 4px 0 rgba($black, 0.25);
        border-radius: 16px;
        margin-bottom: 24px;
        user-select: none;
        &:hover{
            transform: scale(1.03);
        }
        &-heading{
            margin-bottom: 1rem;
            border-bottom: 1px solid #9e9e9e;
            padding-bottom: 1rem;
            &-image{
                color: $white;
                font-size: 3rem;
                box-shadow: 0 4px 4px 0 rgba($black, 0.25);
                margin-right: 1rem;
                background-color: $primary;
                @include circle(72px);
            }
            h1{
                margin: 0;
            }
        }
        &-body{
            p{
                margin: 0;
                font-size: 0.875rem;
            }
        }
    }
}