.reports-wrapper{
    .reports-content{
        .rr-list-wrapper{
            .rr-list-heading{
                padding: 16px 0;
                .rr-toggle-btns {
                    .dropdown{
                        button{
                            @extend .btn;
                            @extend .btn-primary;
                            &:hover {
                                opacity: 0.6;
                            }
                            &:active{
                                background-color: $primary !important;
                            }
                        }
                    }
                    button{
                        &:not(:first-of-type){
                            margin-left: 1rem;
                        }
                    }
                    .column{
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        background: url('../../../../src/assets/images/settings.svg') no-repeat center;
                        // margin-right: 8px;
                    }
                }
                .multi-select {
                    display: none;
                }
                .heading-sub{
                    margin: 0;
                    font-weight: normal;
                    b{
                        color: #212121;
                    }
                    .heading-sub-entries{
                        color: #999;
                        display: block;
                        font-size: 0.875rem;
                        margin-top: 8px;
                    }
                    .table-search {
                        position: relative;
                        &-field {
                            width: 100%;
                            height: 40px;
                            border: 1px solid #d3d3d3;
                            outline: none;
                            padding: 10px 8px 10px 34px;
                            border-radius: 4px;
                            background-color: $white !important;
                            &:focus {
                                + .table-search-close {
                                    display: inline-block;
                                }
                            }
                        }
                        &-icon {
                            display: inline-block;
                            background: url("../../../assets/images/search.svg") no-repeat 8px
                                center;
                        }
                        &-close {
                            top: 50%;
                            right: 10px;
                            position: absolute;
                            display: none;
                            transform: translate(0, -50%);
                        }
                    }
                }
                .rr-list-btns{
                    display: flex;
                    .multi-select-wrap {
                        margin-right: 1rem;
                    }
                    button{
                        &:not(:first-of-type){
                            margin-left: 1rem;
                        }
                        a{
                            color: $white !important;
                        }
                    }
                    .download-docs{
                        width: 17px;
                        height: 17px;
                        display: inline-block;
                        background: url('../../../../src/assets/images/camera.svg') no-repeat center;
                        margin-right: 8px;
                    }
                    .send-excel{
                        width: 17px;
                        height: 17px;
                        display: inline-block;
                        background: url('../../../../src/assets/images/send-excel.svg') no-repeat center;
                        margin-right: 8px;
                    }
                }
            }
            .rr-list-content{
                .nodata-wrapper{
                    width: 100%;
                    height: 100px;
                    display: flex;
                    border-top: 1px solid $border-color;
                    align-items: center;
                    justify-content: center;
                    p{
                        color: #9e9e9e;
                    }
                }
                .no-results-contract{
                    color: rgba($primary, 0.6);
                    width: 100%;
                    height: 200px;
                    border: 2px dashed $border-color;
                    display: flex;
                    font-size: 24px;
                    align-items: center;
                    border-radius: 8px;
                    justify-content: center;
                }
                .r-table-wrapper{
                    height: calc(100vh - 400px);
                    @media (max-width: 1201px) {
                        height: calc(100vh - 325px);
                    }
                    table{
                        thead{
                            tr{
                                top: 0;
                                z-index: 1;
                                position: sticky;
                            }
                        }
                        tr{
                            .column-header {
                                .column {
                                    min-width: 140px;
                                    margin-top: 0.7rem;
                                    min-height: 30px;
                                    .table-search {
                                        &-field {
                                            width: 100%;
                                            height: 36px;
                                            border: 1px solid #d3d3d3;
                                            outline: none;
                                            padding: 10px 8px 10px 34px;
                                            border-radius: 4px;
                                            background-color: $white !important;
                                            &:focus {
                                                + .table-search-close {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                        &-icon {
                                            display: inline-block;
                                            background: url("../../../assets/images/search.svg") no-repeat 8px
                                                center;
                                        }
                                        &-close {
                                            top: 50%;
                                            right: 10px;
                                            position: absolute;
                                            display: none;
                                            transform: translate(0, -50%);
                                        }
                                    }
                                }
                            }
                            .desc{
                                min-width: 250px;
                            }
                            .ticket-checkbox{
                                width: 38px;
                                cursor: pointer;
                                min-width: 38px;
                                .form-group{
                                    margin: 0;
                                    .custom-control{
                                        &-input{
                                            width: 20px;
                                            height: 24px;
                                        }
                                        &-label{
                                            top: unset;
                                            padding: 0 !important;
                                        }   
                                    }
                                }
                            }
                            input{
                                width: 120px;
                                min-width: auto;
                            }
                            .setup-label{
                                color: $text-base-color;
                                position: relative;
                                padding-left: 12px;
                                &:after{
                                    top: 50%;
                                    left: 0;
                                    width: 8px;
                                    height: 8px;
                                    content: '';
                                    display: inline-block;
                                    position: absolute;
                                    transform: translate(0, -50%);
                                    border-radius: 50%;
                                    background-color: #d3d3d3;
                                }
                                &.label-Submitted{
                                    color: #3498db;
                                    &:after{
                                        background-color: #3498db;
                                    }
                                }
                                &.label-Open{
                                    color: #34495E;
                                    &:after{
                                        background-color: #34495E;
                                    }
                                }
                                &.label-Exported{
                                    color: #FFB606;
                                    &:after{
                                        background-color: #FFB606;
                                    }
                                }
                                &.label-Rejected{
                                    color: #C0392B;
                                    &:after{
                                        background-color: #C0392B;
                                    }
                                }
                                &.label-Closed{
                                    color: $primary;
                                    &:after{
                                        background-color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .inapp-filter-wrapper{
        position: relative;
        .inapp-filters{
            border: 1px solid $border-color;
            padding: 24px 24px 0;
            border-radius: 8px;
            .submit-filter{
                button{
                    height: 56px;
                    display: inline-block;
                    min-width: 150px;
                }
            }
        }
    }
}

.column-dropdown {
    max-height: 250px;
    overflow-y: auto;
    .dropdown-item {
        padding: 0.25rem 1rem;
        .custom-control {
            .custom-control-input {
                width: 1rem;
                height: 1rem;
            }
        }
    }
}