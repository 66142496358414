.timecard-filters{
    width: 700px;
    height: 100vh;
    padding: 24px 0;
    position: relative;
    background-color: #fff;
    .filter-heading{
        margin: 0;
        display: flex;
        padding: 0 24px 24px;
        font-size: 1.125rem;
        align-items: center;
        justify-content: space-between;
        .close-filter{
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('../../../../src/assets/images/close.svg') no-repeat center;
        }
    }
    .filter-content{
        height: 100%;
        padding: 0 24px;
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 151px);
    }
    .filter-footer{
        width: 100%;
        bottom: 0;
        display: flex;
        padding: 24px;
        position: absolute;
        align-items: center;
        justify-content: space-between;
        background-color: #F9F9F9;
        box-shadow: 0 -4px 4px rgba(0,0,0,0.25);
        .filters-reset{
            color: #F44336 !important;
            font-size: 0.625rem;
            font-weight: bold;
        }
    }
    .dropdown-content{
        z-index: 2 !important;
    }
}