.create-new-contract{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1050;
  position: fixed;
  overflow: hidden;
  transform: scale(0);
  transform-origin: top;
  transition: all 0.3s linear;
  &.show-contract-popup{
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s linear;
    .contract-backdrop{
      opacity: 1;
      transition: all 0.3s linear;
    }
  }
  .contract-backdrop{
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    background-color: rgba($black, 0.6);
    transition: all 0.3s linear;
  }
  .contract-content{
    margin: 30px auto;
    position: relative;
    max-width: 800px;
    border-radius: 4px;
    background-color: $white;
  }
  .contract-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h5{
      margin: 0;
    }
    span{
        width: 16px;
        height: 16px;
        opacity: 0.5;
        cursor: pointer;
        display: inline-block;
        background: url("../../../../assets/images/close.svg") no-repeat center;
        background-size: 12px;
        &:hover{
          opacity: 1;
        }
    }
  }
  .contract-body {
    padding: 16px 16px 0;
    overflow: auto;
    max-height: 500px;
    .upload-documents {
      .select-File {
        input {
          width: 0;
          height: 0;
          padding: 0;
          opacity: 0;
          + label {
            width: 100%;
            margin: 0;
            display: flex;
            cursor: pointer;
            border: 1px dashed $primary;
            padding: 16px;
            align-items: center;
            justify-content: space-between;
            background-color: #f2f7f6;
            p {
              color: #212121;
              font-size: 1rem;
              margin-bottom: 8px;
              span {
                color: #6e6e6e;
                display: block;
                font-size: 0.75rem;
              }
            }
          }
        }
      }
      .dzu-dropzone{
        border: 2px dashed $border-color;
        overflow: auto;
        .dzu-inputLabel{
          color: $primary;
          margin: 0 !important;
        }
      }
      .uploaded-documents-list {
        margin-top: 8px;
        .uf-list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .uf-detail {
            height: 36px;
            border: 1px solid $border-color;
            margin: 0 10px 10px 0;
            display: flex;
            padding: 6px 16px 6px 6px;
            align-items: center;
            border-radius: 2px;
            justify-content: flex-start;
            background-color: #f2f7f6;
            .uf-image {
              width: 24px;
              height: 24px;
              display: inline-block;
              margin-right: 6px;
              background-size: contain !important;
              &-png {
                background: url("../../../../assets/images/file-png.svg") no-repeat
                  center;
              }
              &-pdf {
                background: url("../../../../assets/images/file-pdf.svg") no-repeat
                  center;
              }
              &-jpg {
                background: url("../../../../assets/images/file-jpg.svg") no-repeat
                  center;
              }
              &-gif {
                background: url("../../../../assets/images/file-pdf.svg") no-repeat
                  center;
              }
            }
            .uf-name {
              color: #212121;
              font-size: $font-size-sm;
              line-height: 19px;
              margin-right: 16px;
              display: block;
              max-width: 100px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .uf-close {
              width: 8px;
              height: 8px;
              cursor: pointer;
              display: inline-block;
              background: url("../../../../assets/images/close.svg") no-repeat center;
              background-size: 8px;
            }
          }
        }
      }
    }
    .fields-wrapper {
      .fields-addition {
        .addition-left {
          border: 1px dashed $primary;
          padding: 16px;
          .al-heading {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 10px;
            padding-bottom: 10px;
            justify-content: space-between;
            a {
              text-decoration: underline;
            }
          }
          .al-body {
            .add-tag-content {
              display: flex;
              flex-wrap: wrap;
              min-height: 25px;
              align-items: center;
              justify-content: flex-start;
              .add-tag {
                cursor: pointer;
                border: 1px solid $primary;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-add {
                  width: 16px;
                  height: 16px;
                  background: url("../../../../assets/images/plus.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              };
              .add-tag-item {
                cursor: pointer;
                border: 1px solid black;
                background-color: $primary;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-add {
                  width: 16px;
                  height: 16px;
                  background: url("../../../../assets/images/plus.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              };
              .add-tag-header {
                cursor: pointer;
                border: 1px solid black;
                background-color: #FFFFC5;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-add {
                  width: 16px;
                  height: 16px;
                  background: url("../../../../assets/images/plus.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              }
            }
            .add-new-tag {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .add-new-input {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                input {
                  border: 1px solid $border-color !important;
                  height: 33px;
                  flex: 1;
                  padding: 0 16px;
                  &::placeholder {
                    color: #c2c2c2 !important;
                  }
                }
                .add-new-buttons {
                  margin-left: 8px;
                  button {
                    padding: 6px;
                    .new-save {
                      width: 16px;
                      height: 16px;
                      background: url("../../../../assets/images/checkmark.svg")
                        no-repeat center;
                    }
                    .new-cancel {
                      width: 16px;
                      height: 16px;
                      background: url("../../../../assets/images/close.svg") no-repeat
                        center;
                    }
                    &:not(:last-of-type) {
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }
        .addition-right {
          border: 1px dashed $primary;
          padding: 16px;
          margin-bottom: 5px;
          .al-heading {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 10px;
            padding-bottom: 10px;
            justify-content: space-between;
            a {
              text-decoration: underline;
            }
          }
          .al-body {
            .add-tag-content {
              display: flex;
              flex-wrap: wrap;
              min-height: 25px;
              align-items: center;
              justify-content: flex-start;
              .add-tag {
                cursor: pointer;
                border: 1px solid $primary;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                line-height: 25px;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-remove {
                  width: 18px;
                  height: 18px;
                  background: url("../../../../assets/images/close.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              };
              .add-tag-item {
                cursor: pointer;
                border: 1px solid black;
                background-color: $primary;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                line-height: 25px;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-remove {
                  width: 18px;
                  height: 18px;
                  background: url("../../../../assets/images/close.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              };
              .add-tag-header {
                cursor: pointer;
                border: 1px solid black;
                background-color: #FFFFC5;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                border-radius: 4px;
                line-height: 25px;
                align-items: center;
                justify-content: center;
                .icon-remove {
                  width: 18px;
                  height: 18px;
                  background: url("../../../../assets/images/close.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              }
            }
          }
        }
        .is-disabled {
          cursor: not-allowed;
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    .contract-footer {
      bottom: 0;
      display: flex;
      padding: 16px 0;
      position: sticky;
      border-top: 1px solid $border-color;
      align-items: center;
      background-color: #fff;
      justify-content: space-between;
      button {
        display: inline-block;
        min-width: 108px;
      }
    }
  }
}