.page-title {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
    padding: 0;
    line-height: 40px;
}

.page-title-sub {
    font-size: 1.1255rem;
    font-weight: $font-weight-bold;
    margin-bottom: 24px;
    padding: 0;
    line-height: 24px;
}

.heading-sub {
    font-size: 1rem;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
    padding: 0;
}

// text -styles
.label-text {
    font-size: 0.875rem;
    font-weight: $font-weight-normal;
    color: #666666;
    display: block;
    line-height: 18px;
}
.label-value {
    font-size: 1.125rem;
    color: #333333;
    display: block;
    line-height: 22px;
    margin-top: 3px;
}

.primary-color {
    color: $primary;
}

.no-text {
    text-indent: -999px;
    display: inline-block;
}
.filter-head {
    font-size: 1.25rem;
    font-weight: $font-weight-normal;
    line-height: 30px;
    color: $gray-800;
    margin-bottom: 1rem;
}
.filter-sub-head {
    font-size: 19px;
    font-weight: $font-weight-normal;
    line-height: 28px;
    color: $gray-800;
    margin-bottom: 1rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    margin: 0 !important;
}

// $merlist: 'active' '#059B00', 'inactive' '#CCCCCC', 'undefined' '#FB9A31';
// .mer-{
//     &gateway {
//         padding-left: 30px !important;
//         background-image: url('#{$assetPath}gateway.svg');
//         background-repeat: no-repeat;
//         background-position: 5px 50%;
//         background-size: 16px 16px;
//     }
//     @each $item in $merlist {
//         &#{nth($item, 1)} {
//         margin-left: 10px;
//         position: relative;
//             &:before {
//                 top: -1px;
//                 left: -9px;
//                 width: 5px;
//                 bottom: -1px;
//                 content: '';
//                 position: absolute;
//                 border-radius: $border-radius/2 0 0 $border-radius/2;
//                 background-color: #{nth($item, 2)};
//             }
//         }
//     }
// }