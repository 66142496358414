@import "./helpers/variables";

.filters-container {
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;
  position: relative;
  padding: 0 40px 10px 0;
  .label {
    font-size: 1rem;
    color: $gray-800;
    display: block;
    margin: 0;
    line-height: 20px;
  }
  .chips {
    display: flex;
    flex-wrap: wrap;
    .chip {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
    //  width: 150px;
      background-color: $chips-bgColor;
      color: $secondary;
      font-size: 1rem;
      line-height: 24px;
      padding: 4px 16px;
      border-radius: 32px;
      margin-right: 0.75rem;
      margin-top: 0.5rem;
      white-space: nowrap;
      border: 1px solid $primary;
      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        max-width: 150px;
      }
      position: relative;
      .icon-close {
        width: 8px;
        height: 8px;
        margin-left: .5rem;
      }
    }
  }
  .icon-delete {
    cursor: pointer;
    display: inline-flex;
    margin: 4px 0 0 12px;
    align-items: center;
    text-decoration: underline;
    background-image: none;
  }
}