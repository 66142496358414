@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import '../../../node_modules/bootstrap/scss/_functions.scss';
@import '../../../node_modules/bootstrap/scss/_mixins.scss';
@import '../../../node_modules/bootstrap/scss/_variables.scss';

@import "./helpers/imports";

@import "./imports";

// Third Party CSS
@import "react-bootstrap-typeahead/css/Typeahead.css";
@import "react-datepicker/dist/react-datepicker.css"; 
@import "@fullcalendar/daygrid/main.css";
@import '../../components/shared/Pagination/Pagination.scss';

html, body {
    font-family: $font-family-base !important;
    font-size: 1rem !important;
    color: #212121 !important;
}

* {
    // font-family: 'Source Sans Pro';
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track  {
        background-color: $disabled-bg;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $disabled;
        border-radius: 5px;
    }
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.text-label-value {
    .text-label {
        color: #666666;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 20px;
    }
    .text-value {
        color: #181818;
        font-size: 0.875rem;
    }
}

// CSS for Khan Multi Select
.multi-select-wrap{
    position: relative;
    &.is-selected{
        .multi-label{
            top: 10px;
            color: $primary;
            font-size: 70%;
        }
        .multi-select{
            .dropdown{
                .dropdown-heading{
                    .dropdown-heading-value{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .multi-label{
        top: 16px;
        left: 16px;
        color: #6e6e6e;
        z-index: 1;
        position: absolute;
        font-size: 1rem;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
    }
    .multi-select{
        .dropdown{
            &[aria-expanded="true"]{
                .dropdown-heading{
                    border-color: $primary !important;
                    // .dropdown-heading-value{
                    //     opacity: 1;
                    // }
                }
            }
            .dropdown-heading{
                height: 56px !important;
                border: solid $border-color !important;
                display: flex !important;
                transition: all 0.3s ease-in-out;
                align-items: flex-end;
                border-width: 0 0 2px 0 !important;
                border-radius: 4px 4px 0 0 !important;
                justify-content: flex-start;
                background-color: #F2F7F6 !important;
                .dropdown-heading-value{
                    color: $text-base-color;
                    opacity: 0;
                    padding: 0 23px 0 16px !important;
                    position: static !important;
                    font-size: 0.875rem;
                    transition: all 0.3s ease-in-out;
                    user-select: none;
                }
                .dropdown-heading-loading-container{
                    display: none !important;
                }
                .dropdown-heading-dropdown-arrow{
                    top: 50%;
                    right: 16px;
                    width: 8px !important;
                    height: 17px !important;
                    display: inline-block !important;
                    position: absolute !important;
                    transform: translate(0, -50%) rotate(-90deg);
                    background: url('../images/back.svg') no-repeat center;
                    margin-left: auto !important;
                    > span{
                        display: none !important;
                    }
                }
            }
            .dropdown-content{
                margin-top: 4px !important;
                border-radius: 4px;
                .select-panel{
                    > div{
                        top: 0;
                        z-index: 1;
                        position: sticky;
                        background-color: white;
                        input{
                            border: 1px solid $border-color !important;
                            background-color: transparent !important;
                            &:focus{
                                border-color: $primary !important;
                            }
                            &::placeholder{
                                color: $border-color;
                            }
                        }
                    }
                    .select-item{
                        margin: 0;
                        background-color: transparent !important;
                        &:hover{
                            background-color: #e9ecef !important;
                        }
                        .item-renderer{
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            input[type="checkbox"] {
                                display: none;
                                & + span {
                                    color: #181818;
                                    display: inline-block;
                                    cursor: pointer;
                                    position: relative;
                                    font-size: 0.875rem;
                                    line-height: 21px;
                                    font-weight: 400;
                                    user-select: none;
                                    padding-left: 1.75rem !important;
                                    &:before {
                                        top: 2px;
                                        left: 0;
                                        content: "";
                                        width: 20px;
                                        height: 20px;
                                        border: 1px solid #cccccc;
                                        position: absolute;
                                        transform: none;
                                        box-shadow: none;
                                        background: #fff;
                                        transition: all 0.2s ease;
                                        border-radius: 2px;
                                    }
                                    &:after {
                                        top: 7px;
                                        left: 0;
                                        content: "";
                                        left: 4px;
                                        width: 12px;
                                        height: 7px;
                                        border: solid #fff;
                                        opacity: 0;
                                        position: absolute;
                                        transform: rotate(-45deg) scale(0);
                                        background: transparent;
                                        transition: all 0.2s ease;
                                        border-width: 0 0 2px 2px;
                                        border-radius: 1px;
                                    }
                                }
                                &:checked + span {
                                    &:after {
                                        opacity: 1;
                                        transform: rotate(-45deg) scale(1);
                                    }
                                    &:before {
                                        background: #FF6E40;
                                        border-color: #FF6E40;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.rbt{
    // .rbt-input-multi{
    //     border: 0 !important;
    //     padding: 0;
    //     border-radius: 4px 4px 0 0;
    //     border-bottom: 1px solid #e2e2e2 !important;
    //     &:focus{
    //         box-shadow: none !important;
    //     }
    //     .rbt-input-wrapper{
    //     }
    //     input{

    //     }
    // }
    .rbt-menu{
        .dropdown-item{
            color: $text-base-color !important;
            &:active, &.active{
                background-color: #e9ecef;
            }
        }
    }
}

// Component imports
@import '../../components/shared/Loader/Loader.scss';
@import '../../components/NavbarTop/NavbarTop.scss';
@import '../../components/NavbarBottom/NavbarBottom.scss';
@import '../../components/shared/Header/Header.scss';
@import '../../components/Landing/Landing.scss';
@import '../../components/Landing/LandingCards/LandingCards.scss';
@import '../../components/Landing/ServiceContracts/ServiceContracts.scss';
@import '../../components/Landing/ServiceContracts/ServiceContractCreate/ServiceContractCreate.scss';
@import '../../components/Landing/ServiceContracts/ServiceContractSetup/ServiceContractSetup.scss';
@import '../../components/Timecard/Timecard.scss';
@import '../../components/Timecard/FilterTimecard/FilterTimecard.scss';
@import '../../components/Timecard/TimecardList/TimecardList.scss';
@import '../../components/Timecard/ReviewTimecard/ReviewTimecard.scss';
@import '../../components/Landing/ServiceTickets/ServiceTickets.scss';
@import '../../components/Landing/ServiceTickets/ServiceTicketCreate.scss';
@import '../../components/Landing/ServiceTickets/ServiceTicketDetails/ServiceTicketDetails.scss';
@import '../../components/Landing/Reports/Reports.scss';