.ticket-details-wrapper {
    .nav.nav-tabs {
        padding-left: 24px;
        margin-bottom: 16px;

        .nav-item {
            &.disabled {
                cursor: not-allowed;
            }

            &:not(:last-of-type) {
                margin-right: 8px;
            }

            .nav-link {
                color: #6e6e6e !important;
                padding: 6px 16px;
                font-size: 0.875rem;
                line-height: 20px;
                border-color: #E2E2E2;

                &.active {
                    color: #fff !important;
                    border-color: #2E7D32;
                    background-color: #2E7D32;
                }
            }
        }
    }

    .ticket-info-wrapper {
        position: relative;

        .ticket-information {
            border: 1px solid $border-color;
            padding: 24px 24px 16px;
            border-radius: 8px;

            .ticket-info-header {
                display: flex;
                margin-bottom: 12px;
                justify-content: space-between;
                align-items: center;

                .ticket-info-title {
                    display: flex;
                    align-items: center;
                }
            }

            .page-title-sub {
                margin: 0 10px 0 0;
            }

            .ticket-info-list {
                .text-label-value {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 4px;
                    justify-content: flex-start;

                    >* {
                        font-size: 0.875rem;

                        &.text-label {
                            color: #999;
                        }

                        &.text-value {
                            color: #212121;
                        }

                        &:not(:last-of-type) {
                            margin-right: 5px;
                        }
                    }
                }

                .page-title-sub {
                    margin: 0.5rem 0 0.25rem;
                }

                .notes-section {
                    margin: 0;

                    .send-note {
                        top: 50%;
                        right: 10px;
                        width: 1rem;
                        height: 1rem;
                        display: block;
                        position: absolute;
                        transform: translate(0, -50%);
                        background: url('../../../../assets/images/send-note.svg') no-repeat center;
                    }

                    textarea {
                        padding: 1rem 2.5rem 1rem 1rem;

                        &::placeholder {
                            color: #999;
                        }
                    }
                }
            }

            .ticket-info-timecard {
                .ticket-timecard-heading {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 10px;
                    justify-content: space-between;

                    .ticket-timecard-calendar {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        >* {
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                opacity: 0.6;
                            }
                        }

                        .ticket-previousdate {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/circle-chevron-left.svg') no-repeat center;
                        }

                        .ticket-nextdate {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/circle-chevron-right.svg') no-repeat center;
                        }

                        .ticket-calendar {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/calendar.svg') no-repeat center;
                            margin-left: 8px;
                        }

                        .page-title-sub {
                            margin: 0 26px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }
                    }

                    .ticket-timecard-view {
                        display: inline-block;
                        margin-left: 10px;

                        .ticket-time {
                            width: 16px;
                            height: 16px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/time.svg') no-repeat center;
                            margin-right: 8px;
                        }
                    }
                }

                .ticket-timecard-body {
                    margin-top: 1rem;
                }
            }
        }
    }

    .st-list-wrapper {
        margin-bottom: 6.5rem;

        .st-list-heading {
            padding: 16px 0;

            .heading-sub {
                margin: 0;
                font-weight: normal;

                b {
                    color: #212121;
                }

                .heading-sub-entries {
                    color: #999;
                    display: block;
                    font-size: 0.875rem;
                    margin-top: 8px;
                }
            }

            .st-list-btns {
                button {
                    &:not(:first-of-type) {
                        margin-left: 1rem;
                    }
                }

                .copy-acti {
                    width: 17px;
                    height: 17px;
                    display: inline-block;
                    background: url('../../../../../src/assets/images/clone.svg') no-repeat center;
                    margin-right: 8px;
                    background-size: contain;
                }

                .ticket-camera {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    background: url('../../../../../src/assets/images/camera.svg') no-repeat center;
                    margin-right: 8px;
                }
            }
        }

        .st-list-content {
            .nodata-wrapper {
                width: 100%;
                height: 100px;
                display: flex;
                border-top: 1px solid $border-color;
                align-items: center;
                justify-content: center;

                p {
                    color: #9e9e9e;
                }
            }

            .r-table-wrapper {
                table {
                    tr {
                        .ticket-checkbox {
                            width: 38px;
                            cursor: pointer;
                            min-width: 38px;

                            .form-group {
                                margin: 0;

                                .custom-control {
                                    &-input {
                                        width: 20px;
                                        height: 24px;
                                    }

                                    &-label {
                                        top: unset;
                                        padding: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cta-btn-wrapper {
        width: 100%;
        bottom: 0;
        z-index: 1;
        display: flex;
        margin: 0 -24px;
        padding: 1.5rem;
        position: fixed;
        align-items: center;
        justify-content: flex-end;
        background-color: $white;
        box-shadow: 0 -4px 4px rgba($black, 0.25);

        button {
            min-width: 120px;
            justify-content: center;
        }
    }
}