.create-new-sticket{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1050;
  position: fixed;
  overflow: hidden;
  transform: translate(0, -100%);
  transform-origin: top;
  transition: all 0.3s linear;
  &.show-sticket-popup{
    opacity: 1;
    transform: translate(0, 0);
    transition: all 0.3s linear;
    .sticket-backdrop{
      opacity: 1;
      transition: all 0.3s linear;
    }
  }
  .sticket-backdrop{
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    background-color: rgba($black, 0.6);
    transition: all 0.3s linear;
  }
  .sticket-content{
    margin: 30px auto;
    position: relative;
    max-width: 800px;
    border-radius: 4px;
    background-color: $white;
  }
  .sticket-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid $border-color;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h5{
      margin: 0;
    }
    span{
        width: 16px;
        height: 16px;
        opacity: 0.5;
        cursor: pointer;
        display: inline-block;
        background: url("../../../assets/images/close.svg") no-repeat center;
        background-size: 12px;
        &:hover{
          opacity: 1;
        }
    }
  }
  .sticket-body {
    padding: 16px 16px 0;
    max-height: 550px;
    overflow-y: auto;

    .service-create-form {
      textarea {
        min-height: 56px !important;
      }
    }
    
    .fields-wrapper {
      .fields-addition {
        .addition-left {
          border: 1px dashed $primary;
          padding: 16px;
          .al-heading {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 10px;
            padding-bottom: 10px;
            justify-content: space-between;
            a {
              text-decoration: underline;
            }
          }
          .al-body {
            .add-tag-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-start;
              .add-tag {
                cursor: pointer;
                border: 1px solid $primary;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-add {
                  width: 16px;
                  height: 16px;
                  background: url("../../../assets/images/plus.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              }
            }
            .add-new-tag {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .add-new-input {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                input {
                  border: 1px solid $border-color !important;
                  height: 33px;
                  flex: 1;
                  padding: 0 16px;
                  &::placeholder {
                    color: #c2c2c2 !important;
                  }
                }
                .add-new-buttons {
                  margin-left: 8px;
                  button {
                    padding: 6px;
                    .new-save {
                      width: 16px;
                      height: 16px;
                      background: url("../../../assets/images/checkmark.svg")
                        no-repeat center;
                    }
                    .new-cancel {
                      width: 16px;
                      height: 16px;
                      background: url("../../../assets/images/close.svg") no-repeat
                        center;
                    }
                    &:not(:last-of-type) {
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }
        .addition-right {
          border: 1px dashed $primary;
          padding: 16px;
          .al-heading {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 10px;
            padding-bottom: 10px;
            justify-content: space-between;
            a {
              text-decoration: underline;
            }
          }
          .al-body {
            .add-tag-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-start;
              .add-tag {
                cursor: pointer;
                border: 1px solid $primary;
                margin: 0 8px 8px 0;
                padding: 0 4px;
                display: flex;
                line-height: 25px;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                .icon-remove {
                  width: 18px;
                  height: 18px;
                  background: url("../../../assets/images/close.svg") no-repeat
                    center;
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }
    .sticket-footer {
      bottom: 0;
      display: flex;
      padding: 16px 0;
      position: sticky;
      border-top: 1px solid $border-color;
      align-items: center;
      background-color: #fff;
      justify-content: space-between;
      button {
        display: inline-block;
        min-width: 108px;
      }
    }
  }
}