.loader-wrapper{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.75);
    &.wrapper-component{
        position: absolute;
    }
    .linear-activity {
        overflow: hidden;
        width: 50%;
        height: 4px;
        background-color: #FF9E80;
        margin: 20px auto;
    }
    .indeterminate {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .indeterminate:before {
        content: '';
        position: absolute;
        height: 100%;
        background-color: #FF6E40;
        animation: indeterminate_first 1.5s infinite ease-out;
    }
    
    .indeterminate:after {
        content: '';
        position: absolute;
        height: 100%;
        background-color: #FF6E40;
        animation: indeterminate_second 1.5s infinite ease-in;
    }
    
    @keyframes indeterminate_first {
        0% {
            left: -100%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }
    
    @keyframes indeterminate_second {
        0% {
            left: -150%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }
}