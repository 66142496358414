.contract-setup-wrapper {
    .nav.nav-tabs {
        padding-left: 24px;
        margin-bottom: 16px;
        .nav-item {
            &:not(:last-of-type) {
                margin-right: 8px;
            }
            .nav-link {
                color: #6e6e6e !important;
                padding: 6px 16px;
                font-size: 0.875rem;
                line-height: 20px;
                border-color: #E2E2E2;
                &.active {
                    color: #fff !important;
                    border-color: #2E7D32;
                    background-color: #2E7D32;
                }
            }
        }
    }
    .ticket-info-wrapper {
        position: relative;
        .ticket-information {
            border: 1px solid $border-color;
            padding: 24px 24px 16px;
            border-radius: 8px;
            .ticket-info-header{
                display: flex;
                margin-bottom: 12px;
                justify-content: space-between;
                align-items: center;
                .ticket-info-title{
                    display: flex;
                    align-items: center;
                }
            }
            .page-title-sub {
                margin-bottom: 12px;
            }
            .ticket-info-list {
                .text-label-value {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 4px;
                    justify-content: flex-start;
                    >* {
                        font-size: 0.875rem;
                        &.text-label {
                            color: #999;
                        }
                        &.text-value {
                            color: #212121;
                            .setup-label {
                                position: relative;
                                padding-left: 12px;
                                &:after {
                                    top: 50%;
                                    left: 0;
                                    width: 8px;
                                    height: 8px;
                                    content: '';
                                    display: inline-block;
                                    position: absolute;
                                    transform: translate(0, -50%);
                                    border-radius: 50%;
                                    background-color: #d3d3d3;
                                }
                                &.label-total {
                                    &:after {
                                        background-color: #d3d3d3;
                                    }
                                }
                                &.label-open {
                                    &:after {
                                        background-color: #34495E;
                                    }
                                }
                                &.label-inprogress {
                                    &:after {
                                        background-color: #FFB606;
                                    }
                                }
                                &.label-done {
                                    &:after {
                                        background-color: #C0392B;
                                    }
                                }
                                &.label-closed {
                                    &:after {
                                        background-color: $primary;
                                    }
                                }
                            }
                        }
                        &:not(:last-of-type) {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .ticket-info-timecard {
                .ticket-timecard-heading {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 10px;
                    justify-content: space-between;
                    .ticket-timecard-calendar {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        >* {
                            transition: all 0.3s ease-in-out;
                            &:hover {
                                opacity: 0.6;
                            }
                        }
                        .ticket-previousdate {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/circle-chevron-left.svg') no-repeat center;
                        }
                        .ticket-nextdate {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/circle-chevron-right.svg') no-repeat center;
                        }
                        .ticket-calendar {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/calendar.svg') no-repeat center;
                            margin-left: 8px;
                        }
                        .page-title-sub {
                            margin: 0 26px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }
                    }
                    .ticket-timecard-view {
                        .ticket-time {
                            width: 16px;
                            height: 16px;
                            display: inline-block;
                            background: url('../../../../../src/assets/images/time.svg') no-repeat center;
                            margin-right: 8px;
                        }
                    }
                }
                .ticket-timecard-body {
                    margin-top: 1rem;
                }
            }
        }
    }
    .st-list-wrapper {
        .st-list-heading {
            padding: 16px 0;
            .heading-sub {
                margin: 0;
                font-weight: normal;
                b {
                    color: #212121;
                }
                .heading-sub-entries {
                    color: #999;
                    display: block;
                    font-size: 0.875rem;
                    margin-top: 8px;
                }
            }
            .st-list-btns {
                button {
                    &:not(:first-of-type) {
                        margin-left: 1rem;
                    }
                }
                .download-docs {
                    width: 17px;
                    height: 17px;
                    display: inline-block;
                    background: url('../../../../../src/assets/images/download.svg') no-repeat center;
                    margin-right: 8px;
                }
            }
        }
        .st-list-content {
            .nodata-wrapper {
                width: 100%;
                height: 100px;
                display: flex;
                border-top: 1px solid $border-color;
                align-items: center;
                justify-content: center;
                p {
                    color: #9e9e9e;
                }
            }
            .r-table-wrapper {
                table {
                    tr {
                        .ticket-checkbox {
                            width: 38px;
                            cursor: pointer;
                            min-width: 38px;
                            .form-group {
                                margin: 0;
                                .custom-control {
                                    &-input {
                                        width: 20px;
                                        height: 24px;
                                    }
                                    &-label {
                                        top: unset;
                                        padding: 0 !important;
                                    }
                                }
                            }
                        }
                        input {
                            width: 100%;
                            min-width: auto;
                        }
                    }
                }
            }
        }
    }
}

.errorBox {
    position: absolute;
    line-height: 1rem;
    .text-danger{
        font-size: 0.75rem;
    }
}