.service-contracts-wrapper {
  .inapp-filter-wrapper {
    position: relative;
    .inapp-filters {
      border: 1px solid $border-color;
      padding: 24px 24px 0;
      border-radius: 8px;
      .submit-filter {
        button {
          height: 56px;
          display: inline-block;
          min-width: 150px;
        }
      }
    }
  }
  .sc-list-wrapper {
    .sc-list-heading {
      padding: 16px 0;
      .heading-sub {
        margin: 0;
      }
    }
    .no-results-contract{
      color: rgba($primary, 0.6);
      width: 100%;
      height: 200px;
      border: 2px dashed $border-color;
      display: flex;
      font-size: 24px;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
    }
    .sc-list-content {
      .nodata-wrapper {
        width: 100%;
        height: 100px;
        display: flex;
        border-top: 1px solid $border-color;
        align-items: center;
        justify-content: center;
        p {
          color: #9e9e9e;
        }
      }
      .contract-wrapper {
        .r-card-header {
          padding: 10px;
          .contract-title {
            color: $primary;
            margin: 0;
            font-size: 0.875rem;
            line-height: 1rem;
            margin-right: 20px;
          }
          .contract-status {
            margin-right: 16px;
          }
          .contract-created {
            color: #212121;
            font-size: 0.75rem;
            font-style: italic;
          }
          .contract-btns {
            button {
              &:not(:last-of-type) {
                margin-right: 8px;
              }
            }
          }
        }
        .r-card-body {
          .row {
            > div {
              &:not(:last-of-type) {
                position: relative;
                &::after {
                  top: 0;
                  right: 0;
                  width: 1px;
                  height: calc(100% - 16px);
                  content: "";
                  display: inline-block;
                  position: absolute;
                  background-color: $border-color;
                }
              }
            }
          }
          .contract-details {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .text-label-value {
              margin-bottom: 16px;
              &:not(:last-of-type) {
                margin-right: 30px;
              }
            }
            &.legends-section {
              .text-label-value {
                .text-label {
                  position: relative;
                  padding-left: 12px;
                  &:after {
                    top: 50%;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    transform: translate(0, -50%);
                    border-radius: 50%;
                    background-color: #d3d3d3;
                  }
                  &.label-total {
                    &:after {
                      background-color: #d3d3d3;
                    }
                  }
                  &.label-draft {
                    &:after {
                      background-color: #ffb606;
                    }
                  }
                  &.label-open {
                    &:after {
                      background-color: #34495e;
                    }
                  }
                  &.label-inprogress {
                    &:after {
                      background-color: #c0392b;
                    }
                  }
                  &.label-done {
                    &:after {
                      background-color: $primary;
                    }
                  }
                  &.label-closed {
                    &:after {
                      background-color: #000;
                    }
                  }
                }
              }
            }
            .chart-wrapper {
              width: 100%;
              height: 20px;
              position: relative;
              background-color: #d3d3d3;
              border-radius: 50px;
              margin-bottom: 16px;
              .chart-content {
                top: 50%;
                left: 0;
                width: 100%;
                height: 20px;
                display: flex;
                position: absolute;
                transform: translate(0, -50%);
                align-items: center;
                border-radius: 50px;
                justify-content: flex-start;
                .chart-status {
                  height: 20px;
                  &:first-child {
                    border-radius: 50px 0 0 50px;
                  }
                  &:last-child {
                    border-radius: 0 50px 50px 0;
                  }
                  &.status-open {
                    width: 0%;
                    background-color: #34495e;
                  }
                  &.status-draft {
                    width: 0%;
                    background-color: #ffb606;
                  }
                  &.status-submitted {
                    width: 0%;
                    background-color: #ffb606;
                  }
                  &.status-inprogress {
                    width: 0%;
                    background-color: #c0392b;
                  }
                  &.status-done {
                    width: 0%;
                    background-color: $primary;
                  }
                  &.status-closed {
                    width: 0%;
                    background-color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
