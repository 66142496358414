@import "./helpers/variables";
.modal {
  .modal-dialog {
    @media (min-width: 576px) {
        max-width: 700px;
    }

    // Update Timecard
    &.update-timecard-modal {
      max-width: 500px;
      .modal-content {
        .modal-header {
          border: 0;
          align-items: center;
          padding-bottom: 0;
        }
        .modal-body {
          padding-bottom: 0;
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Upload Images
    &.upload-new-images {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .upload-documents {
            .select-File {
              input {
                width: 0;
                height: 0;
                padding: 0;
                opacity: 0;
                + label {
                  width: 100%;
                  margin: 0;
                  display: flex;
                  cursor: pointer;
                  border: 1px dashed $primary;
                  padding: 16px;
                  align-items: center;
                  justify-content: space-between;
                  background-color: #f2f7f6;
                  p {
                    color: #212121;
                    font-size: 1rem;
                    margin-bottom: 8px;
                    span {
                      color: #6e6e6e;
                      display: block;
                      font-size: 0.75rem;
                    }
                  }
                }
              }
            }
            .uploaded-documents-list {
              margin-top: 8px;
              .uf-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                .uf-detail {
                  height: 36px;
                  border: 1px solid $border-color;
                  margin: 0 10px 10px 0;
                  display: flex;
                  padding: 6px 16px 6px 6px;
                  align-items: center;
                  border-radius: 2px;
                  justify-content: flex-start;
                  background-color: #f2f7f6;
                  .uf-image {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    margin-right: 6px;
                    background-size: contain !important;
                    &-png {
                      background: url("#{$assetPath}file-png.svg") no-repeat
                        center;
                    }
                    &-pdf {
                      background: url("#{$assetPath}file-pdf.svg") no-repeat
                        center;
                    }
                    &-jpg {
                      background: url("#{$assetPath}file-jpg.svg") no-repeat
                        center;
                    }
                    &-gif {
                      background: url("#{$assetPath}file-pdf.svg") no-repeat
                        center;
                    }
                  }
                  .uf-name {
                    color: #212121;
                    font-size: $font-size-sm;
                    line-height: 1.25rem;
                    margin-right: 16px;
                  }
                  .uf-close {
                    width: 8px;
                    height: 8px;
                    cursor: pointer;
                    display: inline-block;
                    background: url("#{$assetPath}close.svg") no-repeat center;
                    background-size: 8px;
                  }
                }
              }
            }
          }
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Copy Acitivity
    &.copy-activity-modal {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .r-table-wrapper {
            table {
              tr {
                .ticket-checkbox {
                  width: 38px;
                  cursor: pointer;
                  min-width: 38px;
                  .form-group {
                    margin: 0;
                    .custom-control {
                      &-input {
                        width: 20px;
                        height: 24px;
                      }
                      &-label {
                        top: unset;
                        padding: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Download Docs
    &.download-docs-modal {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .r-table-wrapper {
            table {
              tr {
                .ticket-checkbox {
                  width: 38px;
                  cursor: pointer;
                  min-width: 38px;
                  .form-group {
                    margin: 0;
                    .custom-control {
                      &-input {
                        width: 20px;
                        height: 24px;
                      }
                      &-label {
                        top: unset;
                        padding: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Toggle Activity Docs
    &.activity-toggle-wrapper {
      max-width: 500px;
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .nav.nav-tabs {
            padding-left: 24px;
            margin-bottom: 16px;
            .nav-item {
                &:not(:last-of-type) {
                    margin-right: 8px;
                }
                .nav-link {
                    color: #6e6e6e !important;
                    padding: 6px 16px;
                    font-size: 0.875rem;
                    line-height: 20px;
                    border-color: #E2E2E2;
                    &.active {
                        color: #fff !important;
                        border-color: #2E7D32;
                        background-color: #2E7D32;
                    }
                }
            }
          }
          .r-table-wrapper {
            table {
              tr {
                .ticket-checkbox {
                  width: 38px;
                  cursor: pointer;
                  min-width: 38px;
                  .form-group {
                    margin: 0;
                    .custom-control {
                      &-input {
                        width: 20px;
                        height: 24px;
                      }
                      &-label {
                        top: unset;
                        padding: 0 !important;
                      }
                    }
                  }
                }
                .toggle-td{
                  width: 116px;
                  max-width: 116px;
                  .activity-toggle-wrapper{
                    width: 100px;
                    height: 24px;
                    position: relative;
                    .activity-checkbox{
                      width: 100%;
                      margin: 0;
                      height: 100%;
                      cursor: pointer;
                      padding: 0;
                      opacity: 0;
                      z-index: 3;
                      position: relative;
                      &:checked {
                        + .knobs{
                          &:before {
                            left: 42px;
                            content: "DISABLED";
                            background-color: #f44336;
                          }
                        }
                      }
                      + .knobs {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 24px;
                        z-index: 2;
                        position: absolute;
                        transition: 0.3s ease all;
                        border-radius: 8px;
                        background-color: #6e6e6e;
                        &:before{
                          top: 0;
                          left: 0;
                          color: #fff;
                          height: 24px;
                          display: flex;
                          padding: 0 6px;
                          content: "ENABLED";
                          position: absolute;
                          font-size: 10px;
                          transition: 0.3s ease all;
                          line-height: 1;
                          font-weight: bold;
                          align-items: center;
                          border-radius: 8px;
                          justify-content: center;
                          background-color: #03a9f4;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .modal-footer {
            bottom: 0;
            z-index: 4;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Clone Ticket
    &.clone-service-ticket {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 400px;
          padding-bottom: 0;
          .no-timecard-available {
            height: 200px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
          .available-timecard-list {
            .available-timecard {
              padding-bottom: 8px;
              &:not(:last-of-type) {
                margin-bottom: 8px;
                border-bottom: 1px solid $border-color;
              }
              .ticket-checkbox {
                cursor: pointer;
                .form-group {
                  margin: 0;
                  .custom-radio {
                    input[type="radio"] {
                      & + .custom-control-label {
                        cursor: pointer;
                        position: relative;
                        line-height: 24px;
                        display: inline-block;
                        &:before {
                          top: 2px;
                          width: 20px;
                          height: 20px;
                          border: 1px solid #cccccc;
                          transform: none;
                          box-shadow: none;
                          background: #fff;
                          transition: all 0.2s ease;
                          border-radius: 2px;
                        }
                        &:after {
                          top: 7px;
                          left: 4px;
                          width: 12px;
                          height: 7px;
                          border: solid #fff;
                          opacity: 0;
                          transform: rotate(-45deg) scale(0);
                          background: transparent;
                          transition: all 0.2s ease;
                          border-width: 0 0 2px 2px;
                          border-radius: 1px;
                        }
                      }
                      &:checked + .custom-control-label {
                        &:after {
                          opacity: 1;
                          transform: rotate(-45deg) scale(1);
                        }
                        &:before {
                          background: #ff6e40;
                          border-color: #ff6e40;
                        }
                      }
                    }
                  }

                  .custom-control {
                    &-input {
                      width: 20px;
                      height: 24px;
                      &:focus {
                        + label {
                          color: $text-base-color;
                        }
                      }
                      &:checked {
                        + label {
                          font-weight: bold;
                        }
                      }
                    }
                    &-label {
                      top: unset;
                      color: $text-base-color;
                      font-size: 1rem;
                      line-height: 24px;
                    }
                  }
                }
              }
              .available-items {
                .available-crew {
                  width: 45px;
                  position: relative;
                  text-align: center;
                  &:not(:first-of-type) {
                    margin-left: 1.5rem;
                  }
                  .crew-number {
                    top: -1px;
                    right: 0px;
                    color: $white;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    position: absolute;
                    font-size: 0.625rem;
                    align-items: center;
                    border-radius: 16px;
                    justify-content: center;
                    background-color: #ff6e40;
                  }
                }
              }
            }
          }
          .modal-footer {
            bottom: 0;
            z-index: 1;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Add Crew
    &.add-crew-modal {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .addcrew-form {
            .crew-search {
              position: relative;
              margin-bottom: 11px;
              &-field {
                width: 100%;
                height: 40px;
                border: 1px solid #d3d3d3;
                outline: none;
                padding: 10px 8px 10px 34px;
                border-radius: 4px;
                background-color: $white !important;
                &:focus {
                  padding: 10px 34px;
                  + .crew-search-close {
                    display: inline-block;
                  }
                }
              }
              &-icon {
                display: inline-block;
                background: url("../../assets/images/search.svg") no-repeat 8px
                  center;
              }
              &-close {
                top: 50%;
                right: 10px;
                position: absolute;
                transform: translate(0, -50%);
                display: none;
              }
            }
            .crew-search-results {
              color: #999;
              margin: 12px 0 8px;
              font-size: 0.75rem;
              line-height: 24px;
            }
            .available-crew-list {
              height: 300px;
              border: 1px solid #d3d3d3;
              padding: 16px;
              overflow: auto;
              border-radius: 8px;
              margin-bottom: 16px;
              .available-crew {
                &:not(:last-of-type) {
                  margin-bottom: 8px;
                  border-bottom: 1px solid $border-color;
                  padding-bottom: 8px;
                }
                .crew-info {
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  .crew-employee {
                    margin-right: 8px;
                  }
                  .crew-logs {
                    display: flex;
                    font-size: 0.75rem;
                    line-height: 11px;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: flex-start;
                    .crew-name {
                      color: $text-base-color !important;
                      font-size: 1rem;
                      font-weight: bold;
                      line-height: 1.5rem;
                      margin-bottom: 4px;
                      text-transform: uppercase;
                    }
                    .crew-id {
                      color: $text-base-color !important;
                      font-size: 0.75rem;
                      .inc-dec-crew {
                        font-size: 0.75rem;
                        margin-left: 1rem;
                        svg {
                          width: 12px;
                          height: 12px;
                          margin: 0 8px;
                        }
                      }
                    }
                  }
                  .crew-addplus {
                    margin-left: auto;
                    line-height: 18px;
                    button{
                      border: 0;
                      padding: 0;
                    }
                  }
                }
              }
            }
            .add-templabour {
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .temp-labour {
                color: #ff6e40 !important;
                font-size: 0.625rem;
                svg {
                  margin-right: 4px;
                }
              }
            }
          }
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Add templabour
    &.add-templabour-modal {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Add templabour
    &.crew-info-modal {
      .modal-content {
        .modal-header {
          align-items: center;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .crewinfo-wrapper{
            .crew-information{
              .crew-img{
                .crew-user-img{
                  width: 112px;
                  height: 127px;
                  display: inline-block;
                  object-fit: contain;
                }
              }
              .crew-names{
                p{
                  margin-bottom: 0.15rem;
                }
              }
            }
            .crew-certificates-wrapper{
              .crew-certificates-heading{
                margin-bottom: 0.5rem;
                h6{
                  color: $text-base-color;
                  margin: 0;
                  display: inline-block;
                  font-size: 0.75rem;
                  font-weight: bold;
                }
                .send-email{
                  color: #FF6E40 !important;
                  margin: 0;
                  display: flex;
                  font-size: 0.625rem;
                  font-weight: bold;
                  align-items: center;
                  text-transform: uppercase;
                  svg{
                    margin-left: 0.5rem;
                  }
                }
              }
              .crew-certificates-content{
                border: 1px solid $border-color;
                padding: 0 1rem;
                overflow: auto;
                max-height: 163px;
                border-radius: 8px;
                .crew-certificates-list{
                  li{
                    &:not(:last-of-type){
                      border-bottom: 1px solid $border-color;
                    }
                    .crew-certificate{
                      padding: 1rem 0;
                      &:hover{
                        h6{
                          color: $primary;
                          text-decoration: underline;
                        }
                      }
                      h6{
                        color: $text-base-color;
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
            .crew-tags-wrapper{
              .crew-tags-heading{
                margin-bottom: 0.5rem;
                h6{
                  color: $text-base-color;
                  margin: 0;
                  display: inline-block;
                  font-size: 0.75rem;
                  font-weight: bold;
                }
              }
              .crew-tags-content{
                border: 1px solid $border-color;
                padding: 1rem;
                overflow: auto;
                max-height: 163px;
                text-align: center;
                border-radius: 8px;
                .log-tags-list{
                  // display: flex;
                  grid-gap: 14px 0px;
                  // min-width: 48px;
                  // flex-wrap: wrap;
                  // align-items: center;
                  // justify-content: center;
                  .ticket-checkbox{
                    // width: 50%;
                    cursor: pointer;
                    .form-group{
                      margin: 0;
                      .custom-control{
                        &-input{
                          width: 20px;
                          height: 24px;
                        }
                        &-label{
                          top: unset;
                          height: 24px;
                          display: flex;
                          overflow: hidden;
                          white-space: nowrap;
                          align-items: center;
                          margin-right: 10px;
                          text-overflow: ellipsis;
                          justify-content: flex-start;
                          .log-tag{
                            color: #999999;
                            display: flex;
                            // padding: 0 0 0 20px;
                            min-width: 34px;
                            font-size: 0.75rem;
                            line-height: 16px;
                            align-items: center;
                            text-transform: uppercase;
                            justify-content: flex-start;
                            &.tag{
                              &-hol{
                                background: url('../../assets/images/tag-hol.svg') no-repeat 0 center;
                              }
                            }
                            &:not(:first-of-type){
                              margin-left: 10px;
                            }
                          }
                        }   
                      }
                    }
                  }
                }
              }
            }
            .crew-additionaltc-wrapper{
              &:not(:last-of-type){
                margin-bottom: 1rem;
              }
              .crew-additionaltc-heading{
                margin-bottom: 0.5rem;
                h6{
                  color: $text-base-color;
                  margin: 0;
                  display: inline-block;
                  font-size: 0.75rem;
                  font-weight: bold;
                }
              }
              .crew-additionaltc-content{
                border: 1px solid $border-color;
                padding: 1rem;
                overflow: auto;
                max-height: 163px;
                border-radius: 8px;
                .crew-additionaltc-list{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
              }
            }
            .view-certificate{
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 10px;
              &-full{
                max-height: 400px;
              }
            }
          }
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Delete Timecard
    &.delete-timecard-modal {
      .modal-content {
        .modal-header {
          border: 0;
          align-items: center;
          padding-bottom: 0;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .delete-timecard-form {
            .crew-search {
              position: relative;
              margin-bottom: 11px;
              &-field {
                width: 100%;
                resize: none;
                height: 100px;
                border: 1px solid #d3d3d3;
                padding: 10px;
                outline: none;
                border-radius: 4px;
                background-color: $white !important;
              }
            }
          }
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    // Delete Service/Crew
    &.delete-sc-modal {
      .modal-content {
        .modal-header {
          border: 0;
          align-items: center;
          padding-bottom: 0;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }

    &.select-cost-event-modal {
      .modal-content {
        .modal-header {
          border: 0;
          align-items: center;
          padding-bottom: 0;
        }
        .modal-body {
          overflow: auto;
          max-height: 500px;
          padding-bottom: 0;
          .modal-footer {
            bottom: 0;
            padding: 12px 0;
            position: sticky;
            background-color: #fff;
            justify-content: space-between;
            button {
              display: inline-block;
              min-width: 108px;
            }
          }
        }
      }
    }
  }
}

// Add Job Code + Service Ticket
.add-jobcode-modal {
  .modal-content {
    .modal-header {
      align-items: center;
    }
    .modal-body {
      padding-bottom: 0;
      .addjobcode-form {
        .jobcode-search {
          position: relative;
          margin-bottom: 11px;
          &-field {
            width: 100%;
            height: 40px;
            border: 1px solid #d3d3d3;
            outline: none;
            padding: 10px 8px 10px 34px;
            border-radius: 4px;
            background-color: $white !important;
            &:focus {
              padding: 10px 34px;
              + .crew-search-close {
                display: inline-block;
              }
            }
          }
          &-icon {
            display: inline-block;
            background: url("../../assets/images/search.svg") no-repeat 8px
              center;
          }
          &-close {
            top: 50%;
            right: 10px;
            position: absolute;
            transform: translate(0, -50%);
            display: none;
          }
        }
        .jobcode-search-results {
          color: #999;
          margin: 12px 0 8px;
          font-size: 0.75rem;
          line-height: 24px;
        }
        .available-jobcode-list {
          height: 200px;
          border: 1px solid #d3d3d3;
          padding: 16px;
          overflow: auto;
          border-radius: 8px;
          margin-bottom: 16px;
          &.list-right {
            height: 412px;
          }
          .available-jobcode {
            &:not(:last-of-type) {
              margin-bottom: 8px;
              border-bottom: 1px solid $border-color;
              padding-bottom: 8px;
            }
            .jobcode-info {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .jobcode-employee {
                margin-right: 8px;
              }
              .jobcode-logs {
                display: flex;
                font-size: 0.75rem;
                line-height: 11px;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                .jobcode-name {
                  color: $text-base-color !important;
                  font-size: 1rem;
                  font-weight: bold;
                  line-height: 1.5rem;
                  margin-bottom: 4px;
                  text-transform: uppercase;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 220px;
                  text-overflow: ellipsis;
                }
                .jobcode-id {
                  color: $text-base-color !important;
                  font-size: 0.75rem;
                  .inc-dec-jobcode {
                    font-size: 0.75rem;
                    margin-left: 1rem;
                    svg {
                      width: 12px;
                      height: 12px;
                      margin: 0 8px;
                    }
                  }
                }
              }
              .jobcode-addplus {
                margin-left: auto;
                line-height: 18px;
                button{
                  border: 0;
                  padding: 0;
                }
              }
            }
          }
        }
        .modal-footer {
          bottom: 0;
          padding: 12px 0;
          position: sticky;
          background-color: #fff;
          justify-content: space-between;
          button {
            display: inline-block;
            min-width: 108px;
          }
        }
      }
    }
  }
}
