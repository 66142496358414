.reviewtc-wrapper {
    .reviewtc-content {
        .steps-container {
            .steps-list {
                margin: 0 auto;
                max-width: 750px;
                >li {
                    text-align: center;
                    padding: 0 8px;
                    &:first-child {
                        a {
                            &:before {
                                display: none;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            &:after {
                                display: none;
                            }
                        }
                    }
                    &.active {
                        a {
                            &:after,
                            &:before {
                                background-color: $primary;
                            }
                            .step-number {
                                color: $white;  
                                border-color: $primary;
                                background-color: $primary;
                                &:after{
                                    content: '';
                                }
                            }
                            .step-name {
                                color: $primary;
                                font-weight: $font-weight-bold;
                            }
                        }
                    }
                    a {
                        display: flex;
                        position: relative;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        text-decoration: none !important;
                        &:before,
                        &:after {
                            top: 16px;
                            width: 50%;
                            height: 1px;
                            z-index: 1;
                            content: "";
                            display: inline-block;
                            position: absolute;
                            background-color: #d3d3d3;
                        }
                        &:before {
                            left: 0;
                        }
                        &:after {
                            right: 0;
                        }
                        .step-number {
                            color: $dark;
                            width: 30px;
                            height: 30px;
                            border: 1px solid #d3d3d3;
                            z-index: 2;
                            display: flex;
                            position: relative;
                            font-size: 1.375rem;
                            align-items: center;
                            border-radius: 50%;
                            justify-content: center;
                            background-color: #d3d3d3;
                            text-indent: -9999px;
                            &:after{
                                top: 50%;
                                left: 50%;
                                width: 10px;
                                height: 10px;
                                display: inline-block;
                                position: absolute;
                                transform: translate(-50%, -50%);
                                border-radius: 50%;
                                background-color: $white;
                            }
                        }
                        .step-name {
                            color: $dark;
                            display: block;
                            font-size: 1rem;
                            margin-top: 12px;
                            line-height: 1.125rem;
                        }
                    }
                }
            }
        }
        .steps-content{
            .step-visit{
                display: none;
                &.stepvisible{
                    display: block;
                }
            }
        
            .rr-list-wrapper{
                .rr-list-heading{
                    padding: 16px 0;
                    .heading-sub{
                        margin: 0;
                        font-weight: normal;
                        b{
                            color: #212121;
                        }
                        .heading-sub-entries{
                            color: #999;
                            display: block;
                            font-size: 0.875rem;
                            margin-top: 8px;
                        }
                        .table-search {
                            position: relative;
                            &-field {
                                width: 100%;
                                height: 40px;
                                border: 1px solid #d3d3d3;
                                outline: none;
                                padding: 10px 8px 10px 34px;
                                border-radius: 4px;
                                background-color: $white !important;
                                &:focus {
                                    + .table-search-close {
                                        display: inline-block;
                                    }
                                }
                            }
                            &-icon {
                                display: inline-block;
                                background: url("../../../assets/images/search.svg") no-repeat 8px
                                    center;
                            }
                            &-close {
                                top: 50%;
                                right: 10px;
                                position: absolute;
                                display: none;
                                transform: translate(0, -50%);
                            }
                        }
                    }
                    .rr-list-btns{
                        button{
                            &:not(:first-of-type){
                                margin-left: 1rem;
                            }
                            a{
                                color: $white !important;
                            }
                        }
                        .download-docs{
                            width: 17px;
                            height: 17px;
                            display: inline-block;
                            background: url('../../../../src/assets/images/camera.svg') no-repeat center;
                            margin-right: 8px;
                        }
                        .send-excel{
                            width: 17px;
                            height: 17px;
                            display: inline-block;
                            background: url('../../../../src/assets/images/send-excel.svg') no-repeat center;
                            margin-right: 8px;
                        }
                    }
                }
                .rr-list-content{
                    .nodata-wrapper{
                        width: 100%;
                        height: 100px;
                        display: flex;
                        border-top: 1px solid $border-color;
                        align-items: center;
                        justify-content: center;
                        p{
                            color: #9e9e9e;
                        }
                    }
                    .r-table-wrapper{
                        table{
                            tr{
                                .ticket-checkbox{
                                    width: 38px;
                                    cursor: pointer;
                                    min-width: 38px;
                                    .form-group{
                                        margin: 0;
                                        .custom-control{
                                            &-input{
                                                width: 20px;
                                                height: 24px;
                                            }
                                            &-label{
                                                top: unset;
                                                padding: 0 !important;
                                            }   
                                        }
                                    }
                                }
                                input{
                                    width: 120px;
                                    min-width: auto;
                                }
                                .setup-label{
                                    color: $text-base-color;
                                    position: relative;
                                    padding-left: 12px;
                                    &:after{
                                        top: 50%;
                                        left: 0;
                                        width: 8px;
                                        height: 8px;
                                        content: '';
                                        display: inline-block;
                                        position: absolute;
                                        transform: translate(0, -50%);
                                        border-radius: 50%;
                                        background-color: #d3d3d3;
                                    }
                                    &.label-Submitted{
                                        color: #3498db;
                                        &:after{
                                            background-color: #3498db;
                                        }
                                    }
                                    &.label-Open{
                                        color: #34495E;
                                        &:after{
                                            background-color: #34495E;
                                        }
                                    }
                                    &.label-Exported{
                                        color: #FFB606;
                                        &:after{
                                            background-color: #FFB606;
                                        }
                                    }
                                    &.label-Rejected{
                                        color: #C0392B;
                                        &:after{
                                            background-color: #C0392B;
                                        }
                                    }
                                    &.label-Closed{
                                        color: $primary;
                                        &:after{
                                            background-color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .review-certificates{
                margin: 0 0 24px;
                &-header{
                    padding: 16px 0;
                    .heading-sub{
                        margin: 0;
                        font-weight: normal;
                        b{
                            color: #212121;
                        }
                        .heading-sub-entries{
                            color: #999;
                            display: block;
                            font-size: 0.875rem;
                            margin-top: 8px;
                        }
                        .table-search {
                            position: relative;
                            &-field {
                                width: 100%;
                                height: 40px;
                                border: 1px solid #d3d3d3;
                                outline: none;
                                padding: 10px 8px 10px 34px;
                                border-radius: 4px;
                                background-color: $white !important;
                                &:focus {
                                    + .table-search-close {
                                        display: inline-block;
                                    }
                                }
                            }
                            &-icon {
                                display: inline-block;
                                background: url("../../../assets/images/search.svg") no-repeat 8px
                                    center;
                            }
                            &-close {
                                top: 50%;
                                right: 10px;
                                position: absolute;
                                display: none;
                                transform: translate(0, -50%);
                            }
                        }
                    }
                    .rr-list-btns{
                        button{
                            &:not(:first-of-type){
                                margin-left: 1rem;
                            }
                            a{
                                color: $white !important;
                            }
                            svg{
                                margin-right: 8px;
                                path{
                                    fill: $white;
                                }
                            }
                        }
                        .download-docs{
                            width: 17px;
                            height: 17px;
                            display: inline-block;
                            background: url('../../../../src/assets/images/camera.svg') no-repeat center;
                            margin-right: 8px;
                        }
                        .send-excel{
                            width: 17px;
                            height: 17px;
                            display: inline-block;
                            background: url('../../../../src/assets/images/send-excel.svg') no-repeat center;
                            margin-right: 8px;
                        }
                    }
                }
                .certificates-list{
                    .certificate{
                        border: 1px solid $border-color;
                        padding: 24px 24px 0 88px;
                        position: relative;
                        border-radius: 8px;
                        &:not(:first-of-type){
                            margin-top: 1rem;
                        }
                        .employee-dp-wrapper{
                            top: 24px;
                            left: 24px;
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            border-radius: 50%;
                            .employee-dp{
                                width: 40px;
                                height: 40px;
                                object-fit: contain;
                            }
                        }
                        .employee-details-wrapper{
                            .crew-details{
                                display: flex;
                                flex-wrap: wrap;
                                align-items: flex-start;
                                justify-content: space-between;
                                .text-label-value {
                                    margin-bottom: 16px;
                                    &:not(:last-of-type) {
                                        margin-right: 30px;
                                    }
                                }
                            }
                            .crew-certificates{
                                display: flex;
                                flex-wrap: wrap;
                                border-top: 1px solid $border-color;
                                padding-top: 16px;
                                align-items: flex-start;
                                justify-content: stretch;
                                .text-label-value {
                                    padding: 0 35px 0 25px;
                                    position: relative;
                                    margin-bottom: 16px;
                                    &:not(:last-of-type) {
                                        margin-right: 30px;
                                    }
                                    span{
                                        display: block;
                                    }
                                    small{
                                        position: absolute;
                                        &.certi-validation{
                                            top: 3px;
                                            left: 0;
                                            svg{
                                                path{
                                                    fill: $primary;
                                                }
                                            }
                                            &.expired{
                                                svg{
                                                    path{
                                                        fill: $danger;
                                                    }
                                                }
                                            }
                                        }
                                        &.certi-camera{
                                            right: 0;
                                            bottom: 4px;
                                        }
                                    }
                                }
                            }
                            .no-certificates  {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// a{
//     color: white !important;
// }
