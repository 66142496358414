.service-tickets-wrapper{
    .inapp-filter-wrapper{
        position: relative;
        .inapp-filters{
            border: 1px solid $border-color;
            padding: 24px 24px 0;
            border-radius: 8px;
            .submit-filter{
                button{
                    height: 56px;
                    display: inline-block;
                    min-width: 150px;
                }
            }
        }
    }
    .st-list-wrapper{
        .st-list-heading{
            padding: 16px 0;
            .heading-sub{
                margin: 0;
            }
        }
        .st-list-content{
            .nodata-wrapper{
                width: 100%;
                height: 100px;
                display: flex;
                border-top: 1px solid $border-color;
                align-items: center;
                justify-content: center;
                p{
                    color: #9e9e9e;
                }
            }
            .r-table-wrapper{
                table{
                    tr{
                        .ticket-checkbox{
                            width: 38px;
                            cursor: pointer;
                            .form-group{
                                margin: 0;
                                .custom-control{
                                    &-input{
                                        width: 20px;
                                        height: 24px;
                                    }
                                    &-label{
                                        top: unset;
                                        padding: 0 !important;
                                    }   
                                }
                            }
                        }
                    }
                }
            }
            .table-scrollable{
                max-height: 80vh;
                table{
                    th{
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}