/**
 * Theme Variables
 *
 * Default variables are imported from Bootstrap itself
 * Overwrites the default styles via variables
 * defined by the bootstrap framework.
 *
 * Index
 * - Colors
 * - Enable/Disable Feature Options
 * - Scaffolding - Body
 * - Scaffolding - Links
 * - Paragraphs
 * - Grid Columns
 * - Components Padding, margin, border radius sizes
 * - Typography
 * - Tables
 * - Buttons
 * - Forms + Forms Validation
 * - Custom Form Fields
 * - Breadcrumb
 * - Dropdown
 * - Badges
 */
//  @import '../../../../node_modules/bootstrap/scss/_functions.scss';
//  @import '../../../../node_modules/bootstrap/scss/_variables.scss';
/**
  * Color Variables
  *
  * colors are mapped via bootstrap function
 */
 @import "./colors";
 /**
	* Enable/Disable Feature Options
	*
	* Modifies the boolean value to enable/disable various features
	*/

  $enable-caret:                                          true;
  $enable-rounded:                                        true;
  $enable-shadows:                                        true;
  $enable-gradients:                                      false;
  $enable-transitions:                                    true;
  $enable-prefers-reduced-motion-media-query:             true;
  $enable-grid-classes:                                   true;
  $enable-pointer-cursor-for-buttons:                     true;
  $enable-print-styles:                                   true;
  $enable-responsive-font-sizes:                          false;
  $enable-validation-icons:                               false;
  $enable-deprecation-messages:                           false;
/**
	* Scaffolding - Body
	*
	* Modifies the basic for body element
	*/

  $body-bg:                                               $white;
  $body-color:                                            $text-base-color;
  /**
	* Scaffolding - Links
	*
	* Modifies the color for anchored text element
	* Modifies the hover attribute styles for hyperliked element
	* Modifies %age for color intensity
	*/

  $link-color:                                            $primary;
  $link-decoration:                                       none;
  $link-hover-color:                                      $primary;
  $link-hover-decoration:                                 underline;
  $emphasized-link-hover-darken-percentage:               52.5%;
  /**
	* Scaffolding - Paragraphs
	*
	* Overwrites styles for **p** element
	*/

  $paragraph-margin-bottom:                               1rem;

 /**
	* Grid Columns
	*
	* Set the number of columns(by default - 12) and specify the width of the gutters
	*/

  $grid-gutter-width:                                     60px !default;
  /**
	* Components
	*
	* Define common padding and border radius sizes and more
	*/

  $border-width:                                          1px;

  $border-width-left:                                     $border-width;
  $border-width-top:                                      $border-width;
  $border-width-bottom:                                   $border-width;
  $border-width-right:                                    $border-width;
  $border-color:                                          #E2E2E2;

  $border-radius:                                         4px;
  $border-radius-lg:                                      $border-radius;
  $border-radius-sm:                                      $border-radius;

  $rounded-pill:                                          50rem;

  $box-shadow-sm:                                         0 .125rem .25rem rgba($black, .075);
  $box-shadow:                                            none;
  $box-shadow-lg:                                         0 1rem 3rem rgba($black, .175);

  $component-active-color:                                $white ;
  // $component-active-bg:                                   theme-color("primary") ;

  $caret-width:                                           .3em ;
  $caret-vertical-align:                                  $caret-width * .85 ;
  $caret-spacing:                                         $caret-width * .85 ;

  $transition-base:                                       all .2s ease-in-out ;
  $transition-fade:                                       opacity .15s linear ;
  $transition-collapse:                                   height .35s ease ;
  /**
	* Typography - Fonts
	*
	* Modifies Font, line-height, and color for body text, headings, and more
  */

  $font-family-roboto:                                    'Roboto', sans-serif;;
  $font-family-base:                                      $font-family-roboto;

  $font-size-base:                                        1rem; /* 16px */
  $font-size-lg:                                          ($font-size-base * 1.25) ; /* 20px */
  $font-size-sm:                                          ($font-size-base * .875); /*14px */

  $font-weight-lighter:                                   lighter;
  $font-weight-light:                                     300;
  $font-weight-normal:                                    400;
  $font-weight-bold:                                      600;
  $font-weight-bolder:                                    bolder;

  $font-weight-base:                                      $font-weight-normal;

  $line-height-base:                                      1.5;
  $line-height-lg:                                        1.3;
  $line-height-sm:                                        1;

  $h1-font-size:                                          $font-size-base * 2.5;
  $h2-font-size:                                          $font-size-base * 2;
  $h3-font-size:                                          $font-size-base * 1.75;
  $h4-font-size:                                          $font-size-base * 1.5;
  $h5-font-size:                                          $font-size-base * 1.25;
  $h6-font-size:                                          $font-size-base;


// Grid
$grid-columns:                          12;
$grid-gutter-width:                     30px;


$spacer:                                20px;

$input-color: $secondary;
$input-border-width: 0.125rem;
$input-border-color: $border-color;
$input-padding-y:                       10px;
$input-padding-x:                       20px;

// links
$link-color:                              $primary;
$link-hover-color:                        $primary;

// chips

$chips-bgColor: #F2F3FD;
$primary-hover: rgba($primary, 0.1);

$label-text: $gray-500;


 /**
	* Tables
	*
	* Customizes the `.table` component with basic values, each used across all table variations
	*/
 
  $table-cell-padding-y:                                  16px;
  $table-cell-padding-y-sm:                               12px;
  $table-cell-padding-x:                                  20px;
  $table-cell-padding-x-sm:                               8px;
  $table-bg:                                              $white;
  $table-accent-bg:                                       #f6f6f6;
  $table-hover-color:                                     rgba($black, .05);
  $table-hover-bg:                                        $white;
  $table-active-bg:                                       $table-hover-bg;
  $table-color:                                           $body-color;
 
  $table-border-width:                                    $border-width;
  $table-border-color:                                    $border-color;
  
  $table-head-bg:                                         #F7F7FA;
  $table-head-color:                                      $secondary;

  $table-header-fSize:                                    1rem;
  $table-body-color:                                      $secondary;
  $table-shadow-color:                                   #d4d4d4;
  $table-title-header:                                    #f7f7fa;
   /**
	* Buttons + Forms
	*
	* Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
	*/
 
 $input-btn-padding-y:         .5em;
 $input-btn-padding-x:         1em;
 $input-btn-font-family:       null;
 $input-btn-font-size:         $font-size-base;
 $input-btn-line-height:       $line-height-base;
 
 $input-btn-focus-width:       0;
//  $input-btn-focus-color:       rgba($component-active-bg, 0);
 $input-btn-focus-box-shadow:  none;
 
 $input-btn-padding-y-sm:      .25rem;
 $input-btn-padding-x-sm:      .5rem;
 $input-btn-font-size-sm:      $font-size-sm;
 $input-btn-line-height-sm:    $line-height-sm;
 
 $input-btn-padding-y-lg:      .75rem;
 $input-btn-padding-x-lg:      2rem;
 $input-btn-font-size-lg:      $font-size-lg;
 $input-btn-line-height-lg:    $line-height-lg;
 
 $input-btn-border-width:      $border-width;
 $input-disabled-color:        #666666;
 /**
	* Buttons
	*
	* For each of Bootstrap's buttons, define text, background, and border color.
	*/
 
 $btn-padding-y:               9px;
 $btn-padding-x:               21px;
 $btn-font-family:             $input-btn-font-family;
 $btn-font-size:               18px;
 $btn-line-height:             normal;
 
 $btn-padding-y-sm:            $input-btn-padding-y-sm;
 $btn-padding-x-sm:            $input-btn-padding-x-sm;
 $btn-font-size-sm:            $input-btn-font-size-sm;
 $btn-line-height-sm:          $input-btn-line-height-sm;
 
 $btn-padding-y-lg:            $input-btn-padding-y-lg;
 $btn-padding-x-lg:            $input-btn-padding-x-lg;
 $btn-font-size-lg:            $input-btn-font-size-lg;
 $btn-line-height-lg:          $input-btn-line-height-lg;
 
 $btn-border-width:            $input-btn-border-width;
 
 $btn-font-weight:             $font-weight-bold;
 $btn-box-shadow:              inset 0 1px 0 transparent, 0 1px 1px transparent;
 $btn-focus-width:             $input-btn-focus-width;
 $btn-focus-box-shadow:        $input-btn-focus-box-shadow;
 $btn-disabled-opacity:        .4;
 $btn-active-box-shadow:       inset 0 3px 5px transparent;
 
 $btn-link-disabled-color:     $gray-600;
 
 $btn-block-spacing-y:         .5rem;
 /*
	* Allows for customizing button radius independently from global border radius
	*/
 $btn-border-radius:           $border-radius;
 $btn-border-radius-lg:        $border-radius-lg;
 $btn-border-radius-sm:        $border-radius-sm;

 $btn-hover-bg:                #2E7D32;
 $btn-disabled-primary:        #b3b3e6;
 $btn-secondary-hover-bg:      #f0f0fa;
 $btn-secondary-color:         #999999;
 $btn-tertiary-bg:             #f5f5f5;
 $btn-disabled-tertiary:       #c2c2c2;
 
 $btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
 
 $lavendar-lighter-shade:      #F1F3FC;
//custom control switches
$input-height:                   44px;
$custom-file-height:             $input-height !important;
$custom-file-height-inner:       $custom-file-height;
$custom-control-description-disabled-color: #868e96;
$switch-height: calc(#{$input-height} * .4);
$switch-border-radius: $switch-height;
$switch-bg: $disabled;
$switch-checked-bg: $primary;
$switch-disabled-bg: transparent;
$switch-disabled-color: transparent;
$switch-thumb-bg: $white;
$switch-thumb-border-width: 2px;
$switch-thumb-border: $disabled;
$switch-thumb-border-checked: $primary;
$switch-thumb-border-radius: 50%;
$switch-thumb-padding: 2px;
$switch-transition: .2s all;
$custom-control-indicator-checked-box-shadow:  0 0 0 0.2 $primary;
/**
 * Header Panel
 */
 $header-user-name:                  $table-head-color;
 $header-separator:                  $border-color;
 $header-notification-border:        $white;

 /**
 * Left Panel
 */
$left-panel-bg:                     #141429;
$left-menu-item-hover:              $primary;
$left-menu-item-active:             #47478f;
$left-menu-toggler:                 #333366;
$left-menu-text:                    #d1d1f0;
$left-menu-text-active:             $white;
$left-menu-item-bg:                 #323746;
$left-menu-border:                  #333366;

$assetPath : '../images/';

$breadcrumb-bg:      transparent;
$breadcrumb-padding-x:               1.5rem;
$breadcrumb-padding-y:               1rem;
/**
 * Tabs
 */
 $tab-bg:                                 #ffffff;
 $main-tab-bg:                            #f7f7fa;
 $merchant-legend:                        #f0f0fa;
 $merchant-legend-border:                 #5c5cb8;
 $account-legend:                         #f0f0fa;
 $account-legend-border:                  #5c5cb8;
 $txn-legend:                             #5c5cb8;
 $sub-tab-bg:                             #fafafc;
 $sub-tab-border:                         #999999;
 $sub-tab-shadow:                         #c2c2c2;
 $close-icon-bg:                          #e5e5e5;

 $summary-bg:                               #fafafa;
/**
 * Account Nav Panel
 */
 $nav-bg:                           #f7f7fa;
 $nav-content-header:                24px;
 $nav-header-line-height:            40px;
 $nav-hover-bg:                      #f1f3fc;
