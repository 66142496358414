// @import '../../../../node_modules/bootstrap/scss/_functions.scss';
// @import '../../../../node_modules/bootstrap/scss/_variables.scss';
@import './variables';

.form-group {
  margin-bottom: 1.25rem;

  &.has-error {
    .form-control {
      border-color: $danger !important;
    }

    .text-label {
      color: $danger !important;
    }
  }
}

.form-control {
  border: 1px solid $border-color !important;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  min-width: 200px;

  &:focus {
    box-shadow: none !important;
    border-color: $primary !important;
  }

  &.disabled {
    background-color: $disabled-bg;
    border-color: $input-disabled-color;
    color: $input-disabled-color;
    cursor: not-allowed;

    +.placeholder {
      color: $input-disabled-color;
    }
  }
}

textarea.form-control {
  height: auto;
}

.custom-file {
  height: $custom-file-height;

  .custom-file-input:focus~.custom-file-label {
    box-shadow: none !important;
  }

  .custom-file-label {
    border-color: $border-color !important;

    &:focus {
      box-shadow: none !important;
      border-color: $primary !important;
    }

    &:after {
      height: $custom-file-height;
      padding: $input-padding-y $input-padding-x;
    }
  }
}

// Select dropdown
select {
  appearance: none;
  // background-image: url("#{$assetPath}Dropdown.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center right 0.75rem !important;
  background-size: 0.875rem !important;
  min-width: 200px;
  padding-right: 40px;
}

.group-inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .custom-control {
    margin-bottom: 1rem !important;
  }
}

.custom-control {
  position: relative;
  padding-left: 0;

  &:not(:last-of-type) {
    margin-right: 48px;
    margin-bottom: 1rem;
  }

  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .custom-control-label {
    color: #181818;
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    font-weight: 400;
    user-select: none;
    padding-left: 1.75rem !important;

    &:before {
      left: 0;
      content: "";
      position: absolute;
    }

    &:after {
      left: 0;
      content: "";
      position: absolute;
    }
  }

  &.custom-checkbox {
    input[type="checkbox"] {
      &+.custom-control-label {
        cursor: pointer;
        position: relative;
        line-height: 24px;
        display: inline-block;

        &:before {
          top: 2px;
          width: 20px;
          height: 20px;
          border: 1px solid #cccccc;
          transform: none;
          box-shadow: none;
          background: #fff;
          transition: all 0.2s ease;
          border-radius: 2px;
        }

        &:after {
          top: 7px;
          left: 4px;
          width: 12px;
          height: 7px;
          border: solid #fff;
          opacity: 0;
          transform: rotate(-45deg) scale(0);
          background: transparent;
          transition: all 0.2s ease;
          border-width: 0 0 2px 2px;
          border-radius: 1px;
        }
      }

      &:checked+.custom-control-label {
        &:after {
          opacity: 1;
          transform: rotate(-45deg) scale(1);
        }

        &:before {
          background: #FF6E40;
          border-color: #FF6E40;
        }
      }
    }
  }

  &.custom-radio {
    input[type="radio"] {
      &+.custom-control-label {
        cursor: pointer;
        position: relative;
        line-height: 24px;
        display: inline-block;

        &:before {
          top: 2px;
          width: 20px;
          height: 20px;
          border: 1px solid #B4B4B4;
          box-shadow: none;
          background: #fff;
          border-radius: 50%;
        }

        &:after {
          top: 6px;
          left: 4px;
          width: 12px;
          height: 12px;
          opacity: 0;
          transform: scale(0);
          background: transparent;
          transition: all 0.2s ease;
          border-radius: 50%;
        }
      }

      &:checked+.custom-control-label {
        &:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
          background: #FF6E40;
        }

        &::before {
          border-color: #FF6E40;
        }
      }
    }
  }

  &.custom-checkbox,
  &.custom-radio {
    input {

      &:disabled,
      &.disabled {
        &+.custom-control-label {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

//tables
.r-table-wrapper,
.ui-table-wrapper {
  border: 1px solid $border-color;
  z-index: 0;
  position: relative;
  overflow-x: auto;

  table {
    border-collapse: separate !important;
    border-spacing: 0;
    margin: 0;
    table-layout: auto !important;

    thead,
    .ui-table-thead {
      tr {
        border: 0;

        th {
          background: $table-head-bg;
          color: $table-head-color !important;
          font-size: $table-header-fSize !important;
          font-weight: $font-weight-bold !important;
          border-bottom-width: 1px;
          border-top: 0;
          text-transform: capitalize;
          // user-select: none;
          vertical-align: middle;
          white-space: nowrap;
          padding: $table-cell-padding-y-sm $table-cell-padding-x-sm !important;
          position: relative;
          box-shadow: 0 2px 4px $table-shadow-color;

          &:not(:last-child) {
            border-right: $table-border-width solid $table-border-color;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          color: $table-body-color !important;
          vertical-align: middle;
          border-top: 0;
          padding: $table-cell-padding-y $table-cell-padding-x-sm !important;
          border-right: $table-border-width solid $table-border-color;
          border-bottom: $table-border-width solid $table-border-color;

          &:last-child {
            border-right: 0;
          }

          .no-data {
            text-align: center;
            font-style: italic;
            color: $table-head-color;
            padding: 5px 0;
          }
        }

        &:last-child {
          &>td {
            border-bottom: 0;
          }
        }

        &.expandable,
        &.expanding {

          td:first-of-type,
          th:first-of-type {
            border-left: 4px solid $primary;
            padding-left: $table-cell-padding-x - 4px !important;
          }
        }
      }
    }
  }
}

.ui-table-loading {
  opacity: 0.4 !important;
  background: $white;
  min-height: 200px;
}

.ui-table-loading-icon {
  color: $primary !important;
}

// links {

a {
  color: $primary;
  cursor: pointer !important;

  &:hover {
    color: $primary;
    text-decoration: none !important;
  }
}

//buttons
.btn {
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  line-height: $btn-line-height;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  border-radius: $border-radius;

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &-primary {
    @include button-variant($primary,
      $primary,
      $btn-hover-bg,
      $btn-hover-bg,
      darken($primary, 5%),
      darken($primary, 5%) !important);
    color: $white !important;

    &.disabled,
    &:disabled {
      color: $white !important;
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  &-secondary {
    background: transparent;
    @include button-outline-variant($primary, $primary, $btn-secondary-hover-bg, $primary);

    &.disabled,
    &:disabled {
      color: $primary !important;
      background-color: transparent !important;
      border-color: $primary !important;
    }

    &:active {
      background: transparent !important;
      color: $primary !important;
    }

    &:hover,
    &:focus {
      color: rgba($primary, 0.6);
      border-color: $primary;
      background-color: $white;
    }
  }

  &-tertiary {
    background: transparent;
    @include button-outline-variant($btn-secondary-color, $btn-secondary-color, $btn-tertiary-bg, $btn-secondary-color);

    &.disabled,
    &:disabled {
      color: $btn-disabled-tertiary;
      background-color: $white;
      border-color: $btn-secondary-color;
    }

    &:active {
      background: transparent !important;
      color: $btn-secondary-color !important;
    }
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:disabled {
    cursor: default;
  }

  &-sm {
    font-size: .875rem;
    height: $input-height - 10 !important;
  }
}

button {
  border-radius: $border-radius !important;
}

// Legends
.legends-list {
  ul {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-right: 15px;
      }

      h6 {
        margin: 0;
        border-left: 1px solid $border-color;
        padding-left: 12px;
        color: $gray-500;
        font-weight: $font-weight-normal;
      }

      .label-value {
        margin: 0 0 0 .5rem;
        font-size: $font-size-base;
      }
    }
  }
}

.case-priority {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.priority-critical {
    small {
      color: #8F0000;
      border-color: #cc0000;
      background-color: #FAE6E6;
    }
  }

  &.priority-high {
    small {
      color: #995C00;
      border-color: #FF9900;
      background-color: #FFF5E6;
    }
  }

  &.priority-medium {
    small {
      color: #005C7A;
      border-color: #0099CC;
      background-color: #E6F5FA;
    }
  }

  &.priority-caseage {
    small {
      color: #464646;
      border-color: #8C8C8C;
      background-color: #E8E8E8;
    }
  }

  small {
    color: $white;
    min-width: 24px;
    height: 24px;
    display: flex;
    font-weight: $font-weight-bold;
    font-size: .75rem;
    align-items: center;
    margin-right: 5px;
    border-radius: 50%;
    justify-content: center;
    border: 1px solid $label-text;
    background: $gray-150;
  }
}

// Card Styles
.r-card {
  border: 1px solid $border-color;
  border-radius: 4px;

  &-outer {
    >.r-card-header {
      height: 40px;
      cursor: pointer;

      &.has-accordian {
        h3 {
          color: $primary;
        }

        .contract-group-arrow {
          svg {
            transform: rotate(-90deg);

            path {
              fill: $primary;
            }
          }
        }
      }

      h3 {
        color: $text-base-color;
        margin: 0;
        font-size: 1rem;
        line-height: 19px;
        transition: all 0.3s ease-in-out;
      }

      .contract-group-arrow {
        margin-left: 1.5rem;

        svg {
          transform: rotate(-180deg);
          transition: all 0.3s ease-in-out;

          path {
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    >.r-card-body {
      padding: 16px;
    }
  }

  &:not(:first-of-type) {
    margin-top: 16px;
  }

  &-header {
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
  }

  &-body {
    padding: 16px 16px 0;
    border-top: 1px solid $border-color;
  }
}

// Status
.contract-status {
  display: inline-flex;
  padding: 2px 8px;
  font-size: 10px;
  align-items: center;
  border-radius: 4px;
  text-transform: uppercase;
  justify-content: center;
}

.contract-status,
.last-row td {
  &.status-open {
    color: #fff !important;
    background-color: #000 !important;
  }

  &.status-submitted {
    color: #fff !important;
    background-color: #3498DB !important;
  }

  &.status-exported {
    color: #fff !important;
    background-color: #FFB606 !important;
  }

  &.status-rejected {
    color: #fff !important;
    background-color: #C0392B !important;
  }

  &.status-approved {
    color: #fff !important;
    background-color: #62CB31 !important;
  }
}

// Action wrapper
.action-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  >* {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
}