.timecard-list-wrapper {
  @media (min-width: 577px) and (max-width: 1200px) {
    .timecard-list-header-container {
      display: block !important;
      .timecard-list-action {
        text-align: center;
        margin-bottom: 10px;
      }
      .header-wrapper {
        padding-bottom: 0;
        .header-left {
          margin: 0 0 10px;
        }
      }
    }
  }
  .timecard-list-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .timecard-header-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .timecard-list-date {
        margin-left: 40px;
      }
    }
    .timecard-list-date {
      .timecard-list-date-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width: 1201px) {
          flex-direction: column;
          .contract-status{
            margin-top: 8px;
          }
          .timecard-list-date-calendar {
            .page-title-sub {
              margin: 0;
            }
          }
        }
        .timecard-list-date-calendar {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          > * {
            transition: all 0.3s ease-in-out;
            &:hover {
              opacity: 0.6;
            }
          }
          .timecard-list-previousdate {
            width: 20px;
            height: 20px;
            display: inline-block;
            // background: url("../../../../src/assets/images/chevron-left.svg")
            //   no-repeat center;
          }
          .timecard-list-nextdate {
            width: 20px;
            height: 20px;
            display: inline-block;
            // background: url("../../../../src/assets/images/chevron-right.svg")
            //   no-repeat center;
          }
          .timecard-list-calendar {
            margin-left: 8px;
          }
          .page-title-sub {
            margin: 0 26px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            cursor: pointer;
            &.item-open {
              color: #212121;
              svg {
                path {
                  fill: #212121;
                }
              }
            }
            &.item-submitted {
              color: #3498db;
              svg {
                path {
                  fill: #3498db;
                }
              }
            }
            &.item-rejected {
              color: #c0392b;
              svg {
                path {
                  fill: #c0392b;
                }
              }
            }
            &.item-approved {
              color: #62cb31;
              svg {
                path {
                  fill: #62cb31;
                }
              }
            }
            &.item-exported {
              color: #81c784;
              svg {
                path {
                  fill: #81c784;
                }
              }
            }
          }
        }
      }
    }
    .timecard-list-action {
      // background: url('../../../assets/images/clone.svg')
      .icon-clone {
        display: inline-block;
        @include Img("../../../assets/images/clone.svg", 16px, 16px);
      }
      .icon-trash {
        display: inline-block;
        @include Img("../../../assets/images/trash-white.svg", 16px, 18px);
      }
    }
  }
  .timecard-table {
    position: relative;
    // overflow: hidden;
    transition: all 0.3s ease-in-out;
    &.is-filling {
      padding-right: 300px;
      .table-filling-wrapper {
        right: 0;
      }
    }
    .r-table-wrapper {
      height: calc(100vh - 200px);
      background-color: $white;
      table {
        &.table-body-empty {
          height: calc(100% - 84px);
        }
        &.table-costcode-filled {
          width: auto;
        }
        * {
          word-break: break-word;
          // user-select: none;
        }
        .col-heading {
          color: $text-base-color;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 16px;
        }
        thead {
          top: 0;
          z-index: 2;
          position: sticky;
          tr {
            th {
              background-color: $white;
              white-space: normal;
              &:not(:first-child) {
                background-color: #e9ecef;
              }
              padding: 8px !important;
              &.crew-col {
                left: 0;
                z-index: 1;
                position: sticky;
                width: 258px;
                min-width: 258px;
                max-width: 258px;
                z-index: 100;
                background-color: #F2F7F6;
                padding: 8px 16px !important;
                .add-ticket-circle {
                  top: 31px;
                  right: -12px;
                  position: absolute;
                  border-radius: 50%;
                  background-color: $white;
                }
              }
              &.payclass-col {
                width: 114px;
                min-width: 114px;
                max-width: 114px;
                left: 258px;
                z-index: 1;
                position: sticky;
              }
              &.allowances-col {
                left: 372px;
                z-index: 1;
                position: sticky;
                width: 92px;
                min-width: 92px;
                max-width: 92px;
                box-shadow: 2px 2px 4px #d4d4d4;
                .add-ticket-circle {
                  top: 8px;
                  right: -12px;
                  position: absolute;
                  border-radius: 50%;
                  background-color: $white;
                }
              }
              &:last-child {
                border-right: 1px solid $border-color;
              }
              .add-templabour {
                display: flex;
                margin-left: auto;
                align-items: center;
                justify-content: flex-end;
                .temp-labour {
                  color: #ff6e40 !important;
                  font-size: 0.625rem;
                  svg {
                    margin-right: 4px;
                  }
                }
              }
              .crew {
                .col-heading{
                  > span{
                    font-weight: bold;
                  }
                }
                &-search {
                  position: relative;
                  margin-top: 11px;
                  input {
                    width: 100%;
                    height: 40px;
                    outline: none;
                    padding: 10px 8px 10px 34px;
                    border-radius: 4px;
                    // &:focus{
                    //     padding: 10px 34px;
                    //     + .crew-search-close{
                    //         display: inline-block;
                    //     }
                    // }
                  }
                  .rbt-menu {
                    .dropdown-item {
                      color: $text-base-color !important;
                      position: relative;
                      &:not(.disabled) {
                        &:after {
                          top: 9px;
                          right: 9px;
                          width: 14px;
                          height: 14px;
                          content: "";
                          display: inline-block;
                          position: absolute;
                          background: url("../../../assets/images/add-black.svg")
                            no-repeat center;
                          pointer-events: none;
                        }
                      }
                      &:active,
                      &.active {
                        background-color: #e9ecef;
                      }
                    }
                  }
                  &-icon {
                    top: 12px;
                    left: 12px;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    position: absolute;
                    background: url("../../../assets/images/search.svg")
                      no-repeat center center;
                  }
                  &-close {
                    top: 50%;
                    right: 10px;
                    position: absolute;
                    transform: translate(0, -50%);
                    display: none;
                  }
                }
              }
              &.service-ticket-holder {
                width: 180px;
                padding: 0 !important;
                z-index: 0;
                max-width: 180px;
                min-width: 180px;
                text-align: center;
                border-bottom-width: 0px;
                background-color: #e9ecef;
                .service-ticket-name {
                  color: $text-base-color;
                  display: block;
                  padding: 10px 8px;
                  position: relative;
                  font-size: 0.875rem;
                  font-weight: bold;
                  border-bottom: 1px solid $border-color;
                  &:hover {
                    cursor: pointer;
                  }
                }
                .service-ticket-jobcode {
                  display: block;
                  padding: 10px 35px 10px 8px;
                  .service-ticket-trash {
                    top: 50%;
                    right: 10px;
                    position: absolute;
                    transform: translate(0, -50%);
                    line-height: 14px;
                  }
                  > * {
                    padding: 0 8px;
                    &:not(:first-of-type) {
                      position: relative;
                      &::before {
                        top: -13px;
                        left: 0;
                        width: 1px;
                        height: 43px;
                        content: "";
                        display: inline-block;
                        position: absolute;
                        background-color: $border-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tbody {
          tr {
            height: 83px;
            td {
              padding: 10px 8px !important;
              background-color: $white;
              &:not(:first-of-type), &:first-of-type:not([rowspan]){
                &:hover {
                  cursor: pointer;
                  background-color: $merchant-card-header-bg;
                }
              }
              &:last-child {
                border-right: 1px solid $border-color;
              }
              &.payclass-td {
                width: 114px;
                min-width: 114px;
                max-width: 114px;
                left: 258px;
                z-index: 1;
                position: sticky;
              }
              &.allowance-td {
                width: 92px;
                min-width: 92px;
                max-width: 92px;
                left: 372px;
                z-index: 1;
                position: sticky;
                box-shadow: 2px 0 4px #d4d4d4;
              }
              &.crew-holder {
                left: 0;
                z-index: 1;
                position: sticky;
                width: 258px;
                min-width: 258px;
                max-width: 258px;
                background-color: #e9ecef;
              }
              .crew-info {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                .crew-employee {
                  margin-right: 8px;
                  object-fit: cover;
                }
                .crew-logs {
                  display: flex;
                  font-size: 0.75rem;
                  line-height: 11px;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: flex-start;
                  .crew-name {
                    color: $text-base-color !important;
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 1.5rem;
                    margin-bottom: 4px;
                    text-transform: uppercase;
                  }
                  .daily-log {
                    margin-bottom: 6px;
                    &.has-logshare {
                      color: #ffb606;
                      font-weight: bold;
                      padding-right: 16px;
                      background: url("../../../assets/images/employee-share.svg")
                        no-repeat 100% center;
                      background-size: 9px;
                    }
                  }
                }
                .crew-trash {
                  margin-left: auto;
                  line-height: 18px;
                }
              }
              .payclass-info {
                position: relative;
                &:before {
                  top: 50%;
                  left: -9px;
                  width: 5px;
                  height: 35px;
                  display: inline-block;
                  position: absolute;
                  transform: translate(0, -50%);
                  border-radius: 5px;
                  background-color: #ffb606;
                }
                &.has-payclass-modified {
                  &::before {
                    content: "";
                  }
                }
              }
              .allowances-info {
                position: relative;
                &:before {
                  top: 50%;
                  left: -9px;
                  width: 5px;
                  height: 35px;
                  display: inline-block;
                  position: absolute;
                  transform: translate(0, -50%);
                  border-radius: 5px;
                  background-color: #ffb606;
                }
                &.has-allowances-note {
                  &::before {
                    content: "";
                  }
                }
              }
              .log-info {
                text-align: center;
                .log-tags-list {
                  margin: 10px 0 0;
                  display: flex;
                  min-width: 48px;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                  .log-tag {
                    color: #999999;
                    display: flex;
                    // padding: 0 0 0 20px;
                    min-width: 34px;
                    font-size: 0.75rem;
                    line-height: 16px;
                    align-items: center;
                    text-transform: uppercase;
                    justify-content: flex-start;
                    &.tag {
                      &-hol {
                        background: url("../../../assets/images/tag-hol.svg")
                          no-repeat 0 center;
                      }
                    }
                    &:not(:first-of-type) {
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
            .timecard-td-lockedcell {
              pointer-events: none;
            }
            &:last-child {
              td {
                border-bottom: 1px solid $border-color;
              }
            }
          }
          .last-row {
            td {
              .service-ticket-jobcode {
                height: 25px;
                display: block;
                padding: 0px 8px 0px 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                .col-heading {
                  color: $white;
                }
              }
              &.service-ticket-holder {
                width: 180px;
                padding: 0 !important;
                z-index: 10;
                max-width: 180px;
                text-align: center;
                border-bottom-width: 0px;
                background-color: #e9ecef;
              }
            }
          }
        }
      }
    }
    .table-filling-wrapper {
      top: 0;
      right: -300px;
      width: 300px;
      bottom: 0;
      height: 100%;
      position: absolute;
      background-color: $white;
      border: 1px solid #d3d3d3;
      transition: all 0.3s ease;
      .tf-content {
        height: 100%;
        overflow: hidden;
        position: relative;
        overflow-y: auto;
        // padding-bottom: 65px;
        .tf-header {
          top: 0;
          display: flex;
          padding: 16px 24px;
          position: sticky;
          align-items: center;
          justify-content: space-between;
          background-color: $white;
          .tf-title {
            color: $text-base-color;
            margin: 0;
            font-size: 1.125rem;
            line-height: 24px;
          }
          .tf-close {
            width: 14px;
            height: 14px;
            display: inline-block;
            background: url("../../../assets/images/close.svg") no-repeat center;
          }
        }
        .tf-body {
          padding: 0 24px 16px;
          .custom-keypad {
            width: calc(100% + 48px);
            margin: 10px -24px 0;
            padding: 6px 3px 0 3px;
            background-color: #ebebeb;
            .error-cost-events{
              min-height: 24px;
              padding: 0 3px;
              .error-text{
                color: $danger; 
              }
            }
            .keypad-list {
              padding: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              list-style-type: none;
              justify-content: flex-start;
              li {
                width: 33.33%;
                padding: 0 3px;
                margin-bottom: 6px;
                a {
                  color: $black !important;
                  height: 46px;
                  display: flex;
                  font-size: 1.5rem;
                  line-height: 30px;
                  font-weight: 400;
                  align-items: center;
                  border-radius: 5px;
                  justify-content: center;
                  background-color: $white;
                  box-shadow: 0px 1px 0px rgba($black, 0.3);
                  &:active {
                    box-shadow: inset 0px 1px 4px rgba($black, 0.4);
                  }
                  &.clear-wrap {
                    font-size: 1.125rem;
                    box-shadow: none;
                    background-color: transparent;
                  }
                  &.back-wrap {
                    box-shadow: none;
                    background-color: transparent;
                    &::after {
                      width: 23px;
                      height: 18px;
                      content: "";
                      display: inline-block;
                      background: url("../../../assets/images/keypad-back.svg")
                        no-repeat center;
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }
          .custom-logs-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            justify-content: space-between;
            .custom-logs-list {
              padding: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              list-style-type: none;
              justify-content: flex-start;
              li {
                width: 33.33%;
                padding: 0 6px;
                margin-bottom: 6px;
                &:first-child {
                  padding-left: 0;
                }
                &:last-child {
                  padding-right: 0;
                }
                .custom-log {
                  h6 {
                    color: $text-base-color;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.25rem;
                    margin-bottom: 4px;
                    text-transform: capitalize;
                  }
                  input,
                  .custom-block {
                    color: $text-base-color;
                    width: 100%;
                    border: 1px solid #d3d3d3;
                    outline: none;
                    padding: 6px 10px;
                    text-align: right;
                    transition: all 0.3s ease;
                    border-radius: 4px;
                    background-color: #f1f1f1;
                    cursor: pointer;
                    &:focus {
                      border-color: #62cb31;
                      background-color: $white;
                    }
                  }
                  .custom-block {
                    overflow: hidden;
                    text-align: center;
                  }
                  .field-selected {
                    background-color: #62cb31 !important;
                  }
                  .input-hidden {
                    width: 0px;
                    height: 0px;
                    padding: 0px;
                    border: 0px;
                  }
                }
              }
            }
          }
          .log-info {
            text-align: center;
            .log-tags-list {
              margin: 10px 0 0;
              display: flex;
              grid-gap: 4px;
              min-width: 48px;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              .ticket-checkbox {
                cursor: pointer;
                .form-group {
                  margin: 0;
                  .custom-control {
                    &-input {
                      width: 20px;
                      height: 24px;
                    }
                    &-label {
                      top: unset;
                      height: 24px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      .log-tag {
                        color: #999999;
                        display: flex;
                        // padding: 0 0 0 20px;
                        min-width: 34px;
                        font-size: 0.75rem;
                        line-height: 16px;
                        align-items: center;
                        text-transform: uppercase;
                        justify-content: flex-start;
                        &.tag {
                          &-hol {
                            background: url("../../../assets/images/tag-hol.svg")
                              no-repeat 0 center;
                          }
                        }
                        &:not(:first-of-type) {
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
            
          }
          .cost-event-list{
            margin: 0 -24px;
            padding: 0 16px 24px;
            overflow: auto;
            max-height: 300px;
            > li {
              &:not(:first-of-type){
                margin-top: 1rem;
              }
              > a{
                color: $text-base-color !important;
                display: flex;
                padding: 10px;
                align-items: flex-start;
                border-radius: 4px;
                justify-items: center;
                flex-direction: column;
                background-color: #e9ecef;
                transition: all 0.3s ease-in-out;
                &:hover{
                  box-shadow: 0 7px 8px 1px rgba($black, 0.2);
                }
                .ce-heading{
                  font-weight: bold;
                }
              }
            }
          }
          .budget-cost-event-wrapper {
            margin: 10px 0 0 0;
            .budget-cost-event-title{
              color: $text-base-color;
              margin: 0 -10px;
              font-size: 1rem;
              line-height: 24px;
            }
            .budget-cost-event-list{
              margin: 0 -24px;
              padding: 0 10px 12px;
              overflow: auto;
              max-height: 300px;
              > li {
                > a{
                  color: $text-base-color !important;
                  display: flex;
                  padding: 10px;
                  align-items: flex-start;
                  border-radius: 4px;
                  justify-items: center;
                  flex-direction: column;
                  background-color: #e9ecef;
                  transition: all 0.3s ease-in-out;
                  &:hover{
                    box-shadow: 0 7px 8px 1px rgba($black, 0.2);
                  }
                  .ce-heading{
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
        .tf-footer {
          // left: 0;
          // right: 0;
          width: 100%;
          // bottom: 0;
          padding: 16px 24px;
          // position: absolute;
          background-color: #f9f9f9;
        }
      }
    }
    .arrow-wrapper {
      top: 100px;
      width: 50px;
      height: 50px;
      z-index: 3;
      display: inline-block;
      position: absolute;
      border-radius: 50% !important;
      border: 0px;
      background-color: $primary;
      &:after{
        top: 50%;
        width: 20px;
        height: 20px;
        border: 0 solid $white;
        content: "";
        display: inline-block;
        position: absolute;
        transform: translate(0, -50%) rotate(-45deg);
      }
      &.left-arrow{
        left: -22px;
        &:after {
          left: 20px;
          border-width: 3px 0 0 3px;
        }
      }
      &.right-arrow{
        right: -22px;
        &:after {
          right: 20px;
          border-width: 0 3px 3px 0;
        }
      }
    }
  }
}
.cost-event-list-select{
  padding: 0 16px 24px;
  overflow: auto;
  max-height: 300px;
  > li {
    &:not(:first-of-type){
      margin-top: 1rem;
    }
    > a{
      color: $text-base-color !important;
      display: flex;
      padding: 5px;
      border: 1px solid $border-color;
      border-radius: 4px;
      justify-items: center;
      transition: all 0.3s ease-in-out;
      &:hover{
        box-shadow: 0 7px 8px 1px rgba($black, 0.2);
      }
      input{
        margin: 0 10px;
      }
      .ce-heading{
        font-weight: bold;
      }
      .ce-project {
        font-size: 0.75rem;
      }
      .cost-event-select {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
      }
    }
  }
}
.hours-cell {
  background-color: #84c1ea !important;
  .col-heading,
  .log-tag {
    color: $white !important;
  }
}
.crew-log-info {
  text-align: center;
  .log-tags-list {
    margin: 10px 0 0;
    display: flex;
    grid-gap: 4px;
    min-width: 48px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .ticket-checkbox {
      cursor: pointer;
      .form-group {
        margin: 0;
        .custom-control {
          &-input {
            width: 20px;
            height: 24px;
            z-index: inherit;
          }
          &-label {
            top: unset;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            .log-tag {
              color: #999999;
              display: flex;
              // padding: 0 0 0 20px;
              min-width: 34px;
              font-size: 0.75rem;
              line-height: 16px;
              align-items: center;
              text-transform: uppercase;
              justify-content: flex-start;
              &.tag {
                &-hol {
                  background: url("../../../assets/images/tag-hol.svg")
                    no-repeat 0 center;
                }
              }
              &:not(:first-of-type) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.is-disabled {
  cursor: not-allowed !important;
  background-color: #F7F7FA !important;
}
