.timecard-wrapper {
  @media (min-width: 577px) and (max-width: 1200px) {
    .header-wrapper {
      display: block !important;
      padding-bottom: 0;

      .header-left {
        margin: 0 0 10px;
      }
    }
  }

  .added-filters-wrapper {
    margin: 8px 0;

    .added-filter-list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .added-filter {
        height: 18px;
        margin: 0 16px 8px 0;
        display: flex;
        padding: 0 8px;
        align-items: center;
        border-radius: 4px;
        justify-content: flex-start;
        background-color: #f1f4f7;

        &.clear-filter {
          padding: 0;
          background: transparent;

          .label-text-value {
            .label-text {
              color: #f44336;
              font-weight: bold;
            }
          }
        }

        .label-text-value {
          display: flex;
          align-items: center;
          margin-right: 8px;
          justify-content: flex-start;

          .label-text {
            color: #999;
            display: inline-block;
            font-size: 0.625rem;
            line-height: 10px;
            text-transform: uppercase;
          }

          .label-value {
            color: #212121;
            margin: 0;
            display: inline-block;
            font-size: 0.625rem;
            line-height: 10px;
            text-transform: uppercase;
          }
        }

        .close-icon {
          width: 8px;
          height: 8px;
          display: inline-block;
          background: url("../../../src/assets/images/close.svg") no-repeat center;
          background-size: 8px;

          &.close-red {
            background: url("../../../src/assets/images/close-red.svg") no-repeat center;
            background-size: 8px;
          }
        }
      }
    }
  }

  .timecard-content {
    &.filter-open {
      .filter-overlay {
        left: 0;
      }

      .filter-float {
        right: 0;
      }
    }

    .filter-icon {
      top: 25vh;
      width: 53px;
      right: -22px;
      height: 53px;
      cursor: pointer;
      z-index: 5;
      display: flex;
      position: fixed;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      background-color: #34495e;

      &:after {
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        border: solid #fff;
        content: "";
        display: inline;
        position: absolute;
        transform: translate(-50%, -50%) rotate(-45deg);
        border-width: 3px 0 0 3px;
        border-radius: 2px;
      }
    }

    .filter-overlay {
      top: 0;
      left: 100vw;
      right: 0;
      bottom: 0;
      z-index: 5;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.65);
      transition: all 0.3s ease-in-out;
    }

    .filter-float {
      top: 0;
      right: -100vw;
      z-index: 5;
      position: fixed;
      transition: all 0.3s ease-in-out;
    }

    .calendar-wrapper {
      z-index: 0;
      position: relative;
      .fc {
        .fc-toolbar {
          margin-bottom: 16px;

          .fc-toolbar-chunk {
            &:first-child {
              display: flex;
              align-items: center;

              .fc-button-group {
                button {
                  box-shadow: none !important;

                  .fc-icon {
                    display: none;
                  }

                  &:nth-child(1) {
                    background: url("../../../src/assets/images/chevron-left.svg") no-repeat center;
                  }

                  &:nth-child(2) {
                    background: url("../../../src/assets/images/chevron-right.svg") no-repeat center;
                  }

                  &:nth-child(1),
                  &:nth-child(2) {
                    border: 0;
                    height: 28px;
                    display: flex;
                    padding: 0 35px;
                    font-size: 0.75rem;
                    text-indent: -9999px;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;

                    &:active {
                      box-shadow: none;
                    }

                    &:hover {
                      opacity: 0.65;
                    }
                  }
                }
              }

              .fc-today-button {
                color: #212121;
                height: 28px;
                border: 1px solid #e2e2e2;
                margin: 0 8px;
                display: flex;
                padding: 0 35px;
                font-size: 0.75rem;
                box-shadow: none !important;
                align-items: center;
                text-transform: capitalize;
                justify-content: center;
                background-color: #f3f3f3;

                &:disabled {
                  cursor: not-allowed;
                  opacity: 0.4;
                }
              }
            }

            &:last-child {
              .fc-button-group {
                border: 1px solid #e2e2e2;
                display: flex;
                align-items: center;
                border-radius: 4px;
                background-color: #f3f3f3;

                button {
                  color: #212121;
                  border: 0;
                  height: 28px;
                  display: flex;
                  outline: none !important;
                  padding: 0 35px;
                  font-size: 0.75rem;
                  box-shadow: none !important;
                  align-items: center;
                  justify-content: center;
                  background-color: transparent;

                  &:nth-last-of-type(1) {
                    display: none;
                  }

                  &.fc-button-active {
                    color: #fff;
                    background-color: #34495e;
                  }
                }
              }
            }
          }

          .fc-toolbar-title {
            color: #000;
            font-size: 20px;
            font-weight: bold;
          }
        }

        .fc-view-harness {
          height: calc(100vh - 225px) !important;
        }

        .fc-view {

          td,
          th {
            border-style: dashed !important;

            &.fc-daygrid-day {
              cursor: pointer;

              &.fc-day-other {
                &:hover {
                  background: rgba(227, 247, 219, 0.3);

                  .fc-daygrid-day-frame:before {
                    color: rgba(76, 175, 80, 0.3);
                  }
                }
              }

              &:hover {
                background: #e3f7db;

                .fc-daygrid-day-frame:before {
                  top: 5px;
                  right: 5px;
                  color: rgb(76, 175, 80);
                  content: "\f271";
                  display: inline-block;
                  position: absolute;
                  font-size: 1rem;
                  font-weight: bold;
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  font-family: FontAwesome;
                }
              }

              .fc-daygrid-day-frame {
                .fc-daygrid-day-top {
                  flex-direction: row;

                  .fc-daygrid-day-number {
                    color: #a1a1a1 !important;
                    font-size: 1rem;
                    pointer-events: none !important;
                  }
                }
              }
            }
          }

          .fc-scrollgrid {
            border-style: dashed !important;
          }

          .fc-col-header-cell-cushion {
            color: #999 !important;
            display: inline-block;
            padding: 8px 0;
            font-size: 0.875rem;
          }

          &.fc-timeGridWeek-view {
            .fc-timegrid-slots {
              cursor: pointer;

              .fc-timegrid-slot {
                border: 0 !important;
              }
            }

            .fc-timegrid-slot-label-cushion, .fc-timegrid-axis-cushion {
              display: none;
            }

            .fc-timegrid-cols {
              .fc-day {
                cursor: pointer;

                // &.fc-day-other {
                //   &:hover {
                //     background: rgba(227, 247, 219, 0.3);

                //     .fc-daygrid-day-frame:before {
                //       color: rgba(76, 175, 80, 0.3);
                //     }
                //   }
                // }

                &:hover {
                  background: #e3f7db;

                  .fc-daygrid-day-frame:before {
                    top: 5px;
                    right: 5px;
                    color: rgb(76, 175, 80);
                    content: "\f271";
                    display: inline-block;
                    position: absolute;
                    font-size: 1rem;
                    font-weight: bold;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-family: FontAwesome;
                  }
                }
              }
            }

            tbody {
              .fc-scrollgrid-section {
                &:not(.fc-scrollgrid-section-liquid) {
                  // display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

// below css classes to handle calendar add icon
.rbc-now {
  background: #fcf8e3;
}

.rbc-event {
  padding: 0 2px;
  line-height: 20px;
  font-weight: 300;
  font-size: 14px;
}

.rbc-day-bg {
  position: relative;
}

.rbc-date-cell,
.rbc-row,
.rbc-row-content {
  pointer-events: none;
}

.rbc-row-segment {
  pointer-events: all;
}

.rc-fc-newtimecard {
  display: none;
  position: absolute;
  padding-right: 5px;
  right: 0;
}

.rbc-date-cell:hover .rc-fc-newtimecard,
.rbc-day-bg:hover .rc-fc-newtimecard {
  display: inline;
}

.fa-calendar-plus:before {
  content: "\f271";
}

// above css to handle calendar add icon